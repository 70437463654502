<template>
  <div
    v-if="show"
    ref="create-loan-modal"
    class="modal modal__right"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    id="addModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Loan Product
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--CREATE LOAN PRODUCT MODAL-->
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a class="modal__close modal__close--black" @click="$emit('close')">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">
            Create New Loan Product
          </h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <!--                  <div class="error" v-if="!$v.productName.required">-->
                  <!--                    Field is required-->
                  <!--                  </div>-->
                  <!--                  <div class="error" v-if="!$v.productName.minLength">-->
                  <!--                    Name must have at least-->
                  <!--                    {{ $v.productName.$params.minLength.min }} letters.-->
                  <!--                  </div>-->
                  <label class="form__label">Product</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Product"
                    v-model="productName"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">ID</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter ID"
                    v-model="userDefinedLoanId"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <!--                  <div class="error" v-if="!$v.category.required">-->
                  <!--                    Field is required-->
                  <!--                  </div>-->
                  <label class="form__label">Product Category</label>
                  <select class="select select--lg" v-model="category">
                    <option value="null" disabled selected>Select</option>
                    <option
                      v-for="item in enums.productCategory"
                      :key="item.id"
                      v-bind:value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <!--                  <div class="error" v-if="!$v.loanProductType.required">-->
                  <!--                    Field is required-->
                  <!--                  </div>-->
                  <label class="form__label">Product Type</label>
                  <select class="select select--lg" v-model="loanProductType">
                    <option value="" disabled selected>Select</option>
                    <option value="fixed_term_loan">Fixed Term Loan</option>
                  </select>
                </div>
              </div>
            </div>

            <label class="form__label text--black text--capital mb-3"
              >state</label
            >
            <div>
              <label
                for="state"
                class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                >active
                <input
                  type="checkbox"
                  id="state"
                  class="form-"
                  v-model="activated"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <ul id="accordion">
              <!--PRODUCT DESCRIPTION SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#productDescription"
                  aria-expanded="false"
                  aria-controls="productDescription"
                >
                  <!--                  <div class="error" v-if="!$v.productDescription.required">-->
                  <!--                    Field is required-->
                  <!--                  </div>-->
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    product description
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="productDescription"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="form__item">
                      <textarea
                        cols="5"
                        rows="2"
                        class="input form__input"
                        v-model="productDescription"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <!--PRODUCT AVAILABILITY SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#productAvailability"
                  aria-expanded="false"
                  aria-controls="productAvailability"
                >
                  <!--                  <div class="error" v-if="!$v.productAvailability">-->
                  <!--                    At least one option has to be checked-->
                  <!--                  </div>-->
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    product availability
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="productAvailability"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <label class="form__label text--grey text--capital mb-3"
                      >available to</label
                    >
                    <div class="d-flex flex-column">
                      <label
                        for="customers"
                        class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                        >customers
                        <input
                          type="checkbox"
                          id="customers"
                          class="form-"
                          :disabled="forHybridGroups"
                          v-model="forIndividuals"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label
                        for="groups"
                        class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                        >groups
                        <input
                          type="checkbox"
                          id="groups"
                          class="form-"
                          :disabled="forHybridGroups"
                          v-model="forPureGroups"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label
                        for="groups(solidarity)"
                        class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                        >groups(solidarity)
                        <input
                          type="checkbox"
                          id="groups(solidarity)"
                          class="form-"
                          :disabled="forIndividuals"
                          v-model="forHybridGroups"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label
                        for="allbranches"
                        class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                        >all branches
                        <input
                          type="checkbox"
                          id="allbranches"
                          class="form-"
                          v-model="forAllBranches"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <div class="form__item" v-if="!forAllBranches">
                        <multiselect
                          v-model="branchList"
                          :options="allBranches"
                          placeholder="Select branches"
                          label="branchName"
                          track-by="branchKey"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ branch }"
                            >{{ branch.branchName }}</template
                          >
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--ACCOUNT SETTING SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#accountSettings"
                  aria-expanded="false"
                  aria-controls="accountSettings"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    New Account Setting
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="accountSettings"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">ID Type</label>
                          <select
                            class="select select--lg"
                            v-model="idGeneratorType"
                          >
                            <option value="" disabled selected>Select</option>
                            <option
                              v-for="idType in enums.idType"
                              v-bind:value="idType.id"
                              :key="idType.id"
                            >
                              {{ idType.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item" v-if="idGeneratorType == 1">
                          <label class="form__label">
                            Using Template
                            <i
                              class="bi bi-info-circle-fill"
                              data-toggle="tooltip"
                              data-placement="top"
                              style="font-size: 1.6rem"
                              :title="'The symbol # represent Numbers (Ex. 1,2,3...)\nThe symbol @ represent Alphabets (Ex. ABCabc)\nThe symbol $ represent AlphaNumeric (Ex. ABCabc123)'"
                            ></i>
                          </label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            v-model="idPattern"
                          />
                          <!-- <small
                            >$ = numbers, @ = letters and # = alphanumeric
                            characters</small
                          > -->
                        </div>
                        <div class="form__item" v-if="idGeneratorType == 0">
                          <label class="form__label">Starting From</label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            v-model="idPattern"
                            placeholder="Please Enter a Number"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Initial Account State</label
                          >
                          <select
                            class="select select--lg"
                            v-model="accountInitialState"
                          >
                            <option value="" disabled selected>Select</option>
                            <option
                              v-for="item in enums.initialAccountState"
                              v-bind:value="item.id"
                              :key="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label
                            for="allowDisbursement"
                            class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                            >Allow Auto Disbursement
                            <input
                              type="checkbox"
                              id="allowDisbursement"
                              class="form-"
                              v-model="allowAAutoDisbursement"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>

                        <div class="form__item">
                          <label
                            for="requireAcceptance"
                            class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                            >Require Offer Acceptance
                            <input
                              type="checkbox"
                              id="requireAcceptance"
                              class="form-"
                              v-model="requireOfferAcceptance"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>

                        <div class="form__item" v-if="requireOfferAcceptance">
                          <label
                            for="allowOveride"
                            class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                            >Allow Underwriter Overide
                            <input
                              type="checkbox"
                              id="allowOveride"
                              class="form-"
                              v-model="allowUnderwriterOveride"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--LOAN AMOUNT SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#loanAmount"
                  aria-expanded="false"
                  aria-controls="loanAmount"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    loan amount
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="loanAmount" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <p class="text--grey mb-5 text--regular">
                      Loan Amount Constraints (₦)
                    </p>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Default</label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Default"
                            :value="formattedDefaultLoanAmount"
                            @blur="formatAndValidateDefaultAmount($event)"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Minimum</label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Enter minimum"
                            :value="formattedMinLoanAmount"
                            @blur="formatAndValidateMinAmount($event)"
                            @input="validateLoanAmount"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Maximum</label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Enter Maximum"
                            :value="formattedMaxLoanAmount"
                            @blur="formatAndValidateMaxAmount($event)"
                            @input="validateLoanAmount"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Accounts managed under Credit Arrangement</label
                          >
                          <select
                            class="select select--lg"
                            required
                            v-model="lineOfCreditRequirement"
                          >
                            <option value="" disabled selected>Select</option>
                            <option
                              v-for="item in enums.accountManagedUnderCreditArrangement"
                              :key="item.id"
                              v-bind:value="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--INTEREST RATE SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#interestRate"
                  aria-expanded="false"
                  aria-controls="interestRate"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    interest rate
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="interestRate"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Interest Calculation Method</label
                          >
                          <select
                            class="select select--lg"
                            v-model="interestCalculationMethod"
                          >
                            <option value="null" disabled selected>
                              Select Interest Calculation
                            </option>
                            <option
                              v-for="item in enums.interestCalculationMethod"
                              v-bind:value="item.id"
                              :key="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Accrued Interest Posting Frequency</label
                          >
                          <select
                            class="select select--lg"
                            v-model="interestApplicationMethod"
                          >
                            <option value="" disabled selected>Select</option>
                            <option
                              v-for="item in enums.accruedInterestPostingFrequency"
                              v-bind:value="item.id"
                              :key="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >How is the Interest rate charged?</label
                          >
                          <select
                            class="select select--lg"
                            v-model="interestType"
                          >
                            <option value="" disabled selected>Select</option>
                            <option
                              v-for="item in enums.howIsTheInterestRateCharged"
                              v-bind:value="item.id"
                              :key="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div
                          class="form__item"
                          v-if="interestApplicationMethod == 1"
                        >
                          <label
                            for="accrueLateInterest"
                            class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                            >Accrue Interest After Maturity
                            <input
                              type="checkbox"
                              id="accrueLateInterest"
                              class="form-"
                              v-model="accrueLateInterest"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <p class="text--primary form__item_text text--regular mt-5">
                      Interest Rate Constraints (%)
                    </p>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Default</label>
                          <input
                            type="text"
                            v-model="
                              adminInterestProductSettings.defaultInterestRate
                            "
                            class="input form__input form__input--lg"
                            placeholder="Default"
                            @input="
                              controlValue(
                                'adminInterestProductSettings.defaultInterestRate',
                                $event
                              )
                            "
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Minimum</label>
                          <input
                            v-model="
                              adminInterestProductSettings.minInterestRate
                            "
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Enter minimum"
                            @input="
                              controlValue(
                                'adminInterestProductSettings.minInterestRate',
                                $event
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Maximum</label>
                          <input
                            v-model="
                              adminInterestProductSettings.maxInterestRate
                            "
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Enter Maximum"
                            @input="
                              controlValue(
                                'adminInterestProductSettings.maxInterestRate',
                                $event
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Days In Year</label>
                          <select
                            class="select select--lg"
                            v-model="daysInYear"
                          >
                            <option value="null" disabled selected>
                              Select
                            </option>
                            <option
                              v-for="item in enums.daysInYear"
                              :key="item.id"
                              v-bind:value="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Repayments Interest Calculation</label
                          >
                          <select
                            class="select select--lg"
                            v-model="interestAccrualCalculation"
                          >
                            <option value="" disabled selected>Select</option>
                            <option
                              v-for="item in enums.repaymentInterestCalculation"
                              :key="item.id"
                              v-bind:value="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--RE-PAYMENT SCHEDULING SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#repaymentSchedulr"
                  aria-expanded="false"
                  aria-controls="repaymentSchedulr"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    repayment scheduling
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="repaymentSchedulr"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6" v-if="interestCalculationMethod == 2">
                        <div class="form__item">
                          <label class="form__label">Payments Method</label>
                          <select
                            class="select select--lg"
                            v-model="paymentMethod"
                          >
                            <option value="null" disabled selected>
                              Select
                            </option>
                            <option
                              v-for="item in paymentMethodTypes"
                              :key="item.id"
                              v-bind:value="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <label class="form__label"
                          >Payment Interval Method</label
                        >
                        <p class="text--regular form__item_text">Interval</p>
                      </div>
                      <div class="col-5">
                        <div class="form__item">
                          <label class="form__label"
                            >Repayments Are Made Every</label
                          >
                          <input
                            type="number"
                            min="1"
                            class="input form__input form__input--lg"
                            :class="{
                              'inpure-required': repaymentPeriodUnitError,
                            }"
                            placeholder="Enter a number"
                            v-model.number="repaymentPeriodUnit"
                            @input="
                              controlValue('repaymentPeriodUnit', $event),
                                validateRepaymentPeriodUnit()
                            "
                          />
                          <p
                            class="text-danger"
                            v-if="repaymentPeriodUnitError"
                          >
                            Repayments can not be empty or made every 0 days
                          </p>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form__item">
                          <label class="form__label" style="visibility: hidden"
                            >Unit</label
                          >
                          <select
                            class="select select--lg"
                            v-model="repaymentScheduleMethod"
                          >
                            <option value="" disabled selected>Select</option>
                            <option
                              v-for="item in repaymentAreMadeEvery"
                              :key="item.id"
                              v-bind:value="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="form__item">
                          <label class="form__label">PayOff Rate</label>
                          <input
                            type="number"
                            min="0"
                            class="input form__input form__input--lg"
                            placeholder="0.00"
                            v-model.number="payOffRate"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="showInstallmentsConstraints">
                      <p class="text--primary form__item_text text--regular">
                        Installments Constraints (#)
                      </p>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Default</label>
                            <input
                              type="number"
                              min="0"
                              class="input form__input form__input--lg"
                              placeholder="Default"
                              v-model.number="defaultNumInstallments"
                              @input="
                                controlValue('defaultNumInstallments', $event)
                              "
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Minimum</label>
                            <input
                              type="number"
                              min="0"
                              class="input form__input form__input--lg"
                              placeholder="Enter minimum"
                              v-model.number="minNumInstallments"
                              @input="
                                controlValue('minNumInstallments', $event)
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Maximum</label>
                            <input
                              type="number"
                              min="0"
                              class="input form__input form__input--lg"
                              placeholder="Enter Maximum"
                              v-model.number="maxNumInstallments"
                              @input="
                                controlValue('maxNumInstallments', $event)
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="text--primary form__item_text text--regular">
                      First Due Date Offset Constraints (days)
                    </p>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Default</label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Default"
                            v-model="defaultFirstRepaymentDueDateOffset"
                            @input="
                              controlValue(
                                'defaultFirstRepaymentDueDateOffset',
                                $event
                              )
                            "
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Minimum</label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Enter minimum"
                            v-model="minFirstRepaymentDueDateOffset"
                            @input="
                              controlValue(
                                'minFirstRepaymentDueDateOffset',
                                $event
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Maximum</label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Enter Maximum"
                            v-model="maxFirstRepaymentDueDateOffset"
                            @input="
                              controlValue(
                                'maxFirstRepaymentDueDateOffset',
                                $event
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <p class="text--primary form__item_text text--regular">
                          Collect Principal Every
                        </p>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Repayments</label>
                              <input
                                type="text"
                                class="input form__input form__input--lg"
                                placeholder="Enter number of Repayments"
                                v-model="defaultPrincipalRepaymentInterval"
                                @input="
                                  controlValue(
                                    'defaultPrincipalRepaymentInterval',
                                    $event
                                  )
                                "
                              />
                            </div>
                          </div>
                          <div class="col-6" v-if="showGracePeriod">
                            <div class="form__item">
                              <label class="form__label">Grace Period</label>
                              <select
                                class="select select--lg"
                                v-model="gracePeriodType"
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                <option
                                  v-for="item in enums.gracePeriod"
                                  :key="item.id"
                                  v-bind:value="item.id"
                                >
                                  {{ item.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div v-if="gracePeriodType > 0">
                          <p
                            class="text--primary form__item_text text--regular"
                          >
                            Grace Period Constraint
                          </p>
                          <div class="row">
                            <div class="col-4">
                              <div class="form__item">
                                <label class="form__label">Default</label>
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  placeholder="Default"
                                  v-model="defaultGracePeriod"
                                  @input="
                                    controlValue('defaultGracePeriod', $event)
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="form__item">
                                <label class="form__label">Minimum</label>
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  placeholder="Enter minimum"
                                  v-model="minGracePeriod"
                                  @input="
                                    controlValue('minGracePeriod', $event)
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="form__item">
                                <label class="form__label">Maximum</label>
                                <input
                                  type="text"
                                  class="input form__input form__input--lg"
                                  placeholder="Enter Maximum"
                                  v-model="maxGracePeriod"
                                  @input="
                                    controlValue('minGracePeriod', $event)
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label"
                                >Rounding of Repayment Schedules</label
                              >
                              <select
                                class="select select--lg"
                                v-model="roundingRepaymentScheduleMethod"
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                <option
                                  v-for="item in enums.roundingOfRepaymentSchedules"
                                  :key="item.id"
                                  v-bind:value="item.id"
                                >
                                  {{ item.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label"
                                >Rounding of Repayment Currency</label
                              >
                              <select
                                class="select select--lg"
                                v-model="repaymentCurrencyRounding"
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                <option
                                  v-for="item in enums.roundingOfRepaymentCurrency"
                                  :key="item.id"
                                  v-bind:value="item.id"
                                >
                                  {{ item.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label"
                                >Repayments Schedule Editing</label
                              >
                              <div class="d-flex flex-column">
                                <!--<label
                                  v-for="item in enums.repaymentScheduleEditing"
                                  :key="item.description"
                                  class="
                                    has-checkbox
                                    page__body__header__label
                                    text--capital
                                    mb-3
                                    text--black
                                  "
                                  >{{ item.name }}
                                  <input
                                    :id="item.id"
                                    @click="updateRepaymentSchedule"
                                    class="form-"
                                    type="checkbox"
                                  />
                                  <span class="checkmark"></span>
                                </label> -->
                                <label
                                  class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                                >
                                  Adjust Payment Dates
                                  <input
                                    class="form-"
                                    type="checkbox"
                                    v-model="
                                      repaymentScheduleEditOptions.AdjustPaymentDates
                                    "
                                  />
                                  <span class="checkmark"></span>
                                </label>
                                <label
                                  class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                                  >Adjust Principal Payment Schedule
                                  <input
                                    class="form-"
                                    type="checkbox"
                                    v-model="
                                      repaymentScheduleEditOptions.AdjustPrincipalPayment
                                    "
                                  />
                                  <span class="checkmark"></span>
                                </label>
                                <label
                                  class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                                  >Adjust Interest Payment Schedule
                                  <input
                                    class="form-"
                                    type="checkbox"
                                    v-model="
                                      repaymentScheduleEditOptions.AdjustInterestPayment
                                    "
                                  />
                                  <span class="checkmark"></span>
                                </label>
                                <label
                                  class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                                  >Adjust Fee Payment Schedule
                                  <input
                                    class="form-"
                                    type="checkbox"
                                    v-model="
                                      repaymentScheduleEditOptions.AdjustFeePayment
                                    "
                                  />
                                  <span class="checkmark"></span>
                                </label>
                                <label
                                  class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                                  >Adjust Penalty Payment Schedule
                                  <input
                                    class="form-"
                                    type="checkbox"
                                    v-model="
                                      repaymentScheduleEditOptions.AdjustPenaltyPayment
                                    "
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label"
                                >Non Working Days Rescheduling</label
                              >
                              <select
                                class="select select--lg"
                                v-model="repaymentReschedulingMethod"
                                style="font-size: 12px"
                              >
                                <option value="" disabled selected>
                                  Select
                                </option>
                                <option
                                  v-for="item in enums.nonWorkingDaysRescheduling"
                                  :key="item.id"
                                  v-bind:value="item.id"
                                >
                                  {{ item.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--RE-PAYMENT COLLECTION SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#repaymentScheduler"
                  aria-expanded="false"
                  aria-controls="repaymentScheduler"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    repayment collection
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="repaymentScheduler"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <p class="text--grey form__item_text text--regular">
                          Payment Allocation Method
                        </p>
                        <p class="text--black form__item_text text--regular">
                          Horizontal
                        </p>
                      </div>
                      <div class="col-6">
                        <div class="form__item form__item--label">
                          <label class="form__label"
                            >Pre-Payments Acceptance</label
                          >
                          <select
                            class="select select--lg"
                            v-model="prepaymentAcceptance"
                          >
                            <option value="" disabled selected>Select</option>
                            <option
                              v-for="item in enums.prepaymentAcceptance"
                              :key="item.id"
                              v-bind:value="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div
                          class="form__item form__item--label"
                          v-if="prepaymentAcceptance == 0"
                        >
                          <label class="form__label"
                            >Accept Pre-Payment of Future Interest</label
                          >
                          <select
                            class="select select--lg"
                            v-model="futurePaymentsAcceptance"
                          >
                            <option value="" disabled selected>Select</option>
                            <option
                              v-for="item in enums.acceptPrepaymentOfFutureInterest"
                              :key="item.id"
                              v-bind:value="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Re-Payment Allocation Order</label
                          >
                          <div class="grey-bg">
                            <div class="d-flex flex-column">
                              <draggable v-model="repaymentAllocationOrderEnum">
                                <transition-group>
                                  <p
                                    v-for="item in repaymentAllocationOrderEnum"
                                    :key="item.id"
                                    class="text--capital text--black text--regular text--600 mb-3 cursor-pointer"
                                  >
                                    {{ item.name }}
                                  </p>
                                </transition-group>
                              </draggable>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--ARREARS SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#arrearSettings"
                  aria-expanded="false"
                  aria-controls="arrearSettings"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    arrear settings
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="arrearSettings"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <p class="mb-5 form__item_text text--primary text--regular">
                      Arrears Tolerance Period(Days)
                    </p>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Default</label>
                          <input
                            type="text"
                            min="0"
                            class="input form__input form__input--lg"
                            placeholder="Default"
                            v-model="
                              adminArrearsResponseModel.defaultTolerancePeriod
                            "
                            @input="
                              controlValue(
                                'adminArrearsResponseModel.defaultTolerancePeriod',
                                $event
                              )
                            "
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Minimum</label>
                          <input
                            type="txt"
                            min="0"
                            class="input form__input form__input--lg"
                            placeholder="Enter minimum"
                            v-model="
                              adminArrearsResponseModel.minTolerancePeriod
                            "
                            @input="
                              controlValue(
                                'adminArrearsResponseModel.minTolerancePeriod',
                                $event
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Maximum</label>
                          <input
                            type="text"
                            min="0"
                            class="input form__input form__input--lg"
                            placeholder="Enter Maximum"
                            v-model="
                              adminArrearsResponseModel.maxTolerancePeriod
                            "
                            @input="
                              controlValue(
                                'adminArrearsResponseModel.maxTolerancePeriod',
                                $event
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Arrears Days Calculated From</label
                          >
                          <select
                            class="select select--lg"
                            v-model="
                              adminArrearsResponseModel.monthlyToleranceDay
                            "
                            required
                          >
                            <option value="" disabled selected>Select</option>
                            <option
                              v-for="item in enums.arrearsDaysCalculatedFrom"
                              :key="item.id"
                              v-bind:value="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >Non-Working Days in Arrears Tolerance Period and
                            Penalty Calculation Method</label
                          >
                          <select
                            class="select select--lg"
                            required
                            v-model="repaymentReschedulingMethod"
                          >
                            <option value="" disabled selected>Select</option>
                            <option
                              v-for="item in enums.nonWorkingDaysInArrearsTolerancePeriodAndPenaltyCalculationMethod"
                              :key="item.id"
                              v-bind:value="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <p class="mb-5 form__item_text text--primary text--regular">
                      Arrears Tolerance Amount (% of Outstanding Principal)
                    </p>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Defaults</label>
                          <input
                            type="number"
                            class="input form__input form__input--lg"
                            placeholder="Enter Default"
                            v-model="
                              adminArrearsResponseModel.defaultTolerancePercentageOfOutstandingPrincipal
                            "
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Minimum</label>
                          <input
                            type="number"
                            class="input form__input form__input--lg"
                            placeholder="Enter minimum"
                            v-model="
                              adminArrearsResponseModel.minTolerancePercentageOfOutstandingPrincipal
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">maximum</label>
                          <input
                            type="number"
                            min="0"
                            class="input form__input form__input--lg"
                            placeholder="enter maximum"
                            v-model="
                              adminArrearsResponseModel.maxTolerancePercentageOfOutstandingPrincipal
                            "
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label"
                            >With a floor (minimum)</label
                          >
                          <input
                            type="number"
                            min="0"
                            class="input form__input form__input--lg"
                            placeholder="Enter a floor minimum"
                            v-model="generalArrearsSetting.toleranceFloorAmount"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--PENALTY SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#penaltySetings"
                  aria-expanded="false"
                  aria-controls="penaltySetings"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    penalty settings
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="penaltySetings"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="row">
                    <div class="col-6">
                      <div class="pt-5">
                        <div class="form__item">
                          <label class="form__label"
                            >Penalty Calculation Method</label
                          >
                          <select
                            class="select select--lg"
                            v-model="loanPenaltyCalculationMethod"
                          >
                            <option value="null" disabled selected>
                              Select
                            </option>
                            <option
                              v-bind:value="item.id"
                              v-for="item in enums.penaltyCalculationMethod"
                              :key="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="loanPenaltyCalculationMethod != 0">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label"
                          >Penalty Tolerance Period(Days)</label
                        >
                        <input
                          type="number"
                          min="0"
                          class="input form__input form__input--lg"
                          :class="{
                            'input-required': loanPenaltyGracePeriodError,
                          }"
                          placeholder=""
                          v-model.number="loanPenaltyGracePeriod"
                          @input="
                            controlValue('loanPenaltyGracePeriod', $event),
                              validateGracePeriod()
                          "
                        />
                        <p
                          class="text-danger"
                          v-if="loanPenaltyGracePeriodError"
                        >
                          Penalty tolerance period can not be 0
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label"
                          >How is Penalty Rate Charged</label
                        >
                        <input
                          type="text"
                          disabled
                          class="input form__input form__input--lg"
                          :placeholder="
                            loanPenaltyCalculationMethod == 3
                              ? '% Per Year'
                              : '% Per Day'
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="loanPenaltyCalculationMethod != 0">
                    <div class="col-4">
                      <div class="form__item">
                        <label class="form__label"
                          >Default Penalty Rate(%)</label
                        >
                        <input
                          type="text"
                          min="0"
                          class="input form__input form__input--lg"
                          placeholder=""
                          :class="{ 'input-required': defaultPenaltyRateError }"
                          v-model.number="defaultPenaltyRate"
                          @input="
                            controlValue('defaultPenaltyRate', $event),
                              validateDefaultPenaltyRate()
                          "
                        />
                        <p class="text-danger" v-if="defaultPenaltyRateError">
                          Default penalty rate can not be 0
                        </p>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form__item">
                        <label class="form__label"
                          >Minimum Penalty Rate(%)</label
                        >
                        <input
                          type="text"
                          min="0"
                          class="input form__input form__input--lg"
                          :class="{ 'input-required': minPenaltyRateError }"
                          placeholder=""
                          v-model.number="minPenaltyRate"
                          @input="
                            controlValue('minPenaltyRate', $event),
                              validateMinPenaltyRate()
                          "
                        />
                        <p class="text-danger" v-if="minPenaltyRateError">
                          Minimum penalty rate can not be 0
                        </p>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form__item">
                        <label class="form__label"
                          >Maximum Penalty Rate(%)</label
                        >
                        <input
                          type="text"
                          min="0"
                          class="input form__input form__input--lg"
                          :class="{ 'input-required': maxPenaltyRateError }"
                          placeholder=""
                          v-model.number="maxPenaltyRate"
                          @input="
                            controlValue('maxPenaltyRate', $event),
                              validateMaxPenaltyRate()
                          "
                        />
                        <p class="text-danger" v-if="maxPenaltyRateError">
                          Maximum penalty rate can not be 0
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--INTERNAL CONTROLS SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#internalControls"
                  aria-expanded="false"
                  aria-controls="internalControls"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    internal controls
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="internalControls"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="form__item">
                      <div class="d-flex flex-column">
                        <div class="row">
                          <div class="col-12 form__item d-flex">
                            <label
                              class="has-checkbox page__body__header__label text--capital mb-3 text--black pr-5"
                              >Close Dormant Accounts
                              <span
                                class="text--black"
                                v-if="closeDormantAccounts"
                                >after</span
                              >
                              <input
                                class="form-"
                                type="checkbox"
                                v-model="closeDormantAccounts"
                              />
                              <span class="checkmark"></span>
                            </label>
                            <input
                              class="input form__input form__input--lg w-25"
                              type="number"
                              min="0"
                              v-model="dormancyPeriodDays"
                              v-if="closeDormantAccounts"
                            />
                            <h4
                              class="text--black pl-5"
                              v-if="closeDormantAccounts"
                            >
                              days
                            </h4>
                          </div>
                          <div class="col-12 form__item d-flex">
                            <label
                              class="has-checkbox page__body__header__label text--capital mb-3 text--black pr-5"
                              >Lock Arrears Accounts
                              <span
                                class="text--capital text--black"
                                v-if="LockArrearsAccounts"
                                >after</span
                              >
                              <input
                                class="form-"
                                type="checkbox"
                                v-model="LockArrearsAccounts"
                              />
                              <span class="checkmark"></span>
                            </label>
                            <input
                              class="input form__input form__input--lg w-25"
                              type="number"
                              min="0"
                              v-model="lockPeriodDays"
                              v-if="LockArrearsAccounts"
                            />
                            <h4
                              class="text--black pl-5"
                              v-if="LockArrearsAccounts"
                            >
                              days
                            </h4>
                          </div>
                          <div class="col-12">
                            <div class="row">
                              <div class="col-6">
                                <label
                                  class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                                  >Cap Charges
                                  <span
                                    class="text--capital text--black"
                                    v-if="CapCharges"
                                    >more than</span
                                  >
                                  <input
                                    class="form-"
                                    type="checkbox"
                                    v-model="CapCharges"
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12 mb-3">
                                    <input
                                      class="input form__input form__input--lg"
                                      type="number"
                                      min="0"
                                      v-model="cappingPercentage"
                                      v-if="CapCharges"
                                    />
                                  </div>
                                  <div class="col-12">
                                    <div class="pb-3" v-if="CapCharges">
                                      <select
                                        class="select select--lg"
                                        v-model="cappingMethod"
                                      >
                                        <option value="null" disabled selected>
                                          Select
                                        </option>
                                        <option
                                          v-bind:value="item.id"
                                          v-for="item in enums.cappingMethodsList"
                                          :key="item.id"
                                        >
                                          {{ item.name }}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="pb-3" v-if="CapCharges">
                                      <select
                                        class="select select--lg"
                                        v-model="cappingConstraintType"
                                      >
                                        <option value="null" disabled selected>
                                          Select
                                        </option>
                                        <option
                                          v-bind:value="item.id"
                                          v-for="item in enums.cappingConstraintTypesList"
                                          :key="item.id"
                                        >
                                          {{ item.name }}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="pb-3" v-if="CapCharges">
                                      <label
                                        class="has-checkbox page__body__header__label text--capital mb-3 text--black pr-5"
                                        >Apply accrued charges before lock
                                        (capping)
                                        <input
                                          class="form-"
                                          type="checkbox"
                                          v-model="
                                            cappingApplyAccruedChargesBeforeLocking
                                          "
                                        />
                                        <span class="checkmark"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--PAYMENT HOLIDAY SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#paymentHoliday"
                  aria-expanded="false"
                  aria-controls="paymentHoliday"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    Payment Holiday
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="paymentHoliday"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label
                            class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                            >Allow Payment Holiday
                            <input
                              type="checkbox"
                              name="allowArbitraryFees"
                              id="allowArbitraryFees"
                              class="form-"
                              v-model="paymentHoliday.AllowPaymentHoliday"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div v-if="paymentHoliday.AllowPaymentHoliday">
                      <div class="row">
                        <div class="col-6">
                          <div class="pt-5">
                            <div class="form__item">
                              <label class="form__label"
                                >Maximum number of payment holiday</label
                              >
                              <input
                                type="number"
                                min="0"
                                class="input form__input form__input--lg"
                                placeholder=""
                                v-model.number="
                                  paymentHoliday.maximumPaymentNumber
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-4">
                          <div class="form__item">
                            <div
                              class="custom-control custom-radio b-custom-control-lg"
                            >
                              <input
                                type="radio"
                                class="custom-control-input mb-3"
                                value="0"
                                @change="selectType"
                                v-model="paymentHoliday.type"
                                id="forFlatFee"
                              />
                              <label
                                class="custom-control-label"
                                for="forFlatFee"
                              >
                                Flat Amount
                              </label>
                            </div>
                            <div v-if="this.paymentHoliday.IsFlatType" class="mt-3">
                              <label class="form__label"
                                >Enter flat fee</label
                              >
                              <input
                                type="number"
                                min="0"
                                class="input form__input form__input--lg"
                                placeholder=""
                                v-model.number="paymentHoliday.FeeAmount"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form__item">
                            <div
                              class="custom-control custom-radio b-custom-control-lg"
                            >
                              <input
                                type="radio"
                                class="custom-control-input mb-3"
                                value="1"
                                @change="selectType"
                                v-model="paymentHoliday.type"
                                id="forPercentageFee"
                              />
                              <label
                                class="custom-control-label"
                                for="forPercentageFee"
                              >
                                % of repayment due
                              </label>
                            </div>
                            <div v-if="this.paymentHoliday.IsPercentageType" class="mt-3">
                              <label class="form__label"
                                >Enter percentage (must not be greater than 100)</label
                              >
                              <input
                                type="number"
                                min="0"
                                class="input form__input form__input--lg"
                                placeholder=""
                                v-model.number="paymentHoliday.FeePercentage"
                                @input="checkForPercentageConstraint()"
                              />
                              <p class="text-danger" v-if="checkForPercentageConstraint()">
                                Percentage can not be greater than 100
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form__item">
                            <div
                              class="custom-control custom-radio b-custom-control-lg"
                            >
                              <input
                                type="radio"
                                class="custom-control-input mb-3"
                                value="2"
                                @change="selectType"
                                v-model="paymentHoliday.type"
                                id="forCustomType"
                              />
                              <label
                                class="custom-control-label"
                                for="forCustomType"
                              >
                                custom
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--PRODUCT FEES SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#productFees"
                  aria-expanded="false"
                  aria-controls="productFees"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    product fees
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="productFees" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label
                            class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                            >Allow Arbitrary Fees
                            <input
                              type="checkbox"
                              name="allowArbitraryFees"
                              id="allowArbitraryFees"
                              class="form-"
                              v-model="allowArbitrayFees"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label
                            class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                            >Show Inactive Fees
                            <input
                              type="checkbox"
                              class="form-"
                              name="showInactiveFees"
                              id="showInactiveFees"
                              v-model="showInactiveFees"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div v-for="item in productFeeForms" v-bind:key="item.id">
                      <div
                        :class="{
                          isInActive: !item.active && !showInactiveFees,
                        }"
                        class="form__item p-5 border border-radius"
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="form__item">
                              <label
                                class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                                >{{ item.active ? "Active" : "InActive" }}
                                <input
                                  type="checkbox"
                                  v-bind:id="item.id"
                                  class="form-"
                                  v-bind:checked="item.active"
                                  @change="
                                    productFeeStatusChange($event, item.id)
                                  "
                                />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">name</label>
                              <input
                                type="text"
                                v-bind:value="item.feeName"
                                @change="productFeeNameChange($event, item.id)"
                                class="input form__input form__input--lg"
                                placeholder="Enter name"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item form__item--label">
                              <label class="form__label">Type</label>
                              <select
                                class="select select--lg"
                                v-bind:value="item.feeTrigger"
                                @change="productFeeTypeChange($event, item.id)"
                              >
                                <option value="" disabled selected>
                                  Select type
                                </option>
                                <option
                                  v-for="feeType in item.types"
                                  :key="feeType.id"
                                  :value="feeType.id"
                                >
                                  {{ feeType.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item form__item--label">
                              <label class="form__label">fee payment</label>
                              <select
                                v-bind:value="item.amountCalculationMethod"
                                @change="
                                  productFeePaymentChange($event, item.id)
                                "
                                class="select select--lg"
                                required
                              >
                                <option disabled selected>
                                  Select fee payment
                                </option>
                                <option
                                  v-for="payment in item.payment"
                                  :key="payment.id"
                                  :value="payment.id"
                                >
                                  {{ payment.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">{{
                                (
                                  item.amountCalculationMethod == 1 ||
                                  item.amountCalculationMethod == 4
                                )
                                  ? "%"
                                  : "Amount"
                              }}</label>
                              <input
                                type="text"
                                :value="inputCurrencyFormats(item.feeAmount)"
                                class="input form__input form__input--lg"
                                @keyup="productFeeAmountChange($event, item.id)"
                                placeholder="Enter amount"
                              />
                            </div>
                          </div>
                          <div
                            class="col-6"
                            v-if="
                              item.feeTrigger == 1 ||
                              item.feeTrigger == 2 ||
                              item.feeTrigger == 3 ||
                              item.feeTrigger == 5
                            "
                          >
                            <div class="form__item form__item--label">
                              <label class="form__label">fee application</label>
                              <select
                                v-bind:value="item.feeApplication"
                                @change="
                                  productFeeApplicationChange($event, item.id)
                                "
                                class="select select--lg"
                                required
                              >
                                <option disabled>Select fee application</option>
                                <option
                                  v-for="application in item.application"
                                  :key="application.id"
                                  :value="application.id"
                                >
                                  {{ application.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          class="button ml-auto page__body__header__button button--cancel text--capital"
                          @click="removeFeeForm(item.id)"
                        >
                          <span class="d-inline-flex">delete</span>
                        </button>
                      </div>
                    </div>

                    <div class="form__item">
                      <button
                        type="button"
                        class="button page__body__header__button button--grey text--capital"
                      >
                        <ion-icon
                          name="add-outline"
                          class="text--regular"
                        ></ion-icon>
                        <span class="d-inline-flex ml-2" @click="addFeeForm()"
                          >add fee</span
                        >
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!--PRODUCT LINK SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#productLink"
                  aria-expanded="false"
                  aria-controls="productLink"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    product link
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="productLink" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item">
                      <label
                        for="enableLink"
                        class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                        >enable links
                        <input
                          type="checkbox"
                          id="enableLink"
                          class="form-"
                          v-model="accountLinkagEnabled"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="row" v-if="accountLinkagEnabled">
                      <div class="col-6">
                        <div class="form__item form__item--label">
                          <label class="form__label">Settlement Account</label>
                          <select
                            class="select select--lg"
                            required
                            v-model="linkAbleSavingsProductKey"
                          >
                            <option
                              v-for="(item, i) in depositAccounts"
                              :key="i"
                              :value="item.id"
                            >
                              {{ item.productName }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item form__item--label">
                          <label class="form__label">Settlement Options</label>
                          <select
                            class="select select--lg"
                            v-model="settlementOptions"
                          >
                            <option value="null" disabled selected>
                              Select
                            </option>
                            <option
                              v-for="item in enums.settlementOptions"
                              :key="item.id"
                              :value="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--SECURITIES SECTION-->
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#securities"
                  aria-expanded="false"
                  aria-controls="securities"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    securities
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="securities" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item">
                      <label
                        for="enableGuarantors"
                        class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                        >enable guarantors
                        <input
                          type="checkbox"
                          id="enableGuarantors"
                          class="form-"
                          v-model="
                            adminProductSecuritySetting.isGuarantorsEnabled
                          "
                          v-bind:checked="
                            adminProductSecuritySetting.isGuarantorsEnabled
                          "
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form__item">
                      <label
                        for="enableCollateral"
                        class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                        >enable Collateral
                        <input
                          type="checkbox"
                          id="enableCollateral"
                          class="form-"
                          v-model="
                            adminProductSecuritySetting.isCollateralEnabled
                          "
                          v-bind:checked="
                            adminProductSecuritySetting.isCollateralEnabled
                          "
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div
                      class="row align-items-center"
                      v-if="
                        adminProductSecuritySetting.isGuarantorsEnabled ||
                        adminProductSecuritySetting.isCollateralEnabled
                      "
                    >
                      <div class="col-8">
                        <div class="form__item form__item--label">
                          <label class="form__label">Required Security</label>
                          <input
                            type="number"
                            min="0"
                            class="input form__input form__input--lg"
                            v-model.number="
                              adminProductSecuritySetting.requiredGuaranties
                            "
                          />
                        </div>
                      </div>
                      <div class="form__item">
                        <p class="text--grey text--small">% Of Loan Amount</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--ACCOUNTING RULES SECTION-->
              <!-- <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#accountingRules"
                  aria-expanded="false"
                  aria-controls="accountingRules"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    accounting rules
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="accountingRules"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="form__item">
                      <label class="form__label">Methodology</label>
                      <select
                        class="select select--lg"
                        v-model="accountingMethod"
                      >
                        <option value="" disabled selected>Select</option>
                        <option
                          v-for="item in enums.methodology"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div> -->
            </ul>
            <div class="form__item">
              <div class="form__action">
                <button
                  id="save-changes"
                  type="button"
                  class="button form__button form__button--lg"
                  @click="confirmSave()"
                  :disabled="this.process"
                >
                  {{ process ? "Saving..." : "save changes" }}
                </button>
                <a
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="$emit('close')"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css";
</style>
<script>
import { CreateLoanProductInitialState } from "./config_modules/initialStates";

import { required, minLength } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import LoanApiService from "@/core/services/api.loan.service";
import branchService from "@/core/services/organization-service/branches.service";
import currencyMixin from "@/mixins/currencyMixin";
// const product_enums = require( "./config_modules/products_enums");
const CheckProductAvailability = (vm) => {
  if (
    vm.forAllBranches ||
    vm.forHybridGroups ||
    vm.forIndividuals ||
    vm.forPureGroups
  ) {
    return true;
  } else {
    return false;
  }
};
export default {
  name: "CreateLoan",
  mixins: [currencyMixin],
  components: {
    draggable,
    Multiselect,
  },
  props: {
    show: Boolean,
    close: Function,
    depositAccounts: Array,
    loadingDepositAccounts: Boolean,
  },
  data: function () {
    return CreateLoanProductInitialState();
  },
  validations: {
    productName: {
      required,
      minLength: minLength(3),
    },
    productDescription: {
      required,
      minLength: minLength(3),
    },
    loanProductType: {
      required,
      minLength: minLength(3),
    },
    category: {
      required,
      minLength: minLength(3),
    },
    userDefinedLoanId: {
      required,
    },
    productAvailability: CheckProductAvailability,
  },
  methods: {
    resetWindow: function () {
      Object.assign(this.$data, CreateLoanProductInitialState());
    },
    checkForPercentageConstraint() {
      if (this.paymentHoliday.FeePercentage > 100) {
        this.$emit('alertWarning', 'Payment holiday percentage fee can not be greater than 100');
        return true;
      }
      return false;
    },
    selectType() {
      if (this.paymentHoliday.type == 0) {
        this.paymentHoliday.IsFlatType = true;
        this.paymentHoliday.IsCustomType = false;
        this.paymentHoliday.IsPercentageType = false;
        this.paymentHoliday.FeePercentage = 0;
        return;
      }
      if (this.paymentHoliday.type == 1) {
        this.paymentHoliday.IsFlatType = false;
        this.paymentHoliday.IsCustomType = false;
        this.paymentHoliday.IsPercentageType = true;
        this.paymentHoliday.FeeAmount = 0;
        return;
      }
      if (this.paymentHoliday.type == 2) {
        this.paymentHoliday.IsFlatType = false;
        this.paymentHoliday.IsCustomType = true;
        this.paymentHoliday.IsPercentageType = false;
        this.paymentHoliday.FeeAmount = 0;
        this.paymentHoliday.FeePercentage = 0;
        return;
      }
      this.paymentHoliday.IsFlatType = false;
      this.paymentHoliday.IsCustomType = false;
      this.paymentHoliday.IsPercentageType = false;
      this.paymentHoliday.FeeAmount = 0;
      this.paymentHoliday.FeePercentage = 0;
    },
    formatAndValidateDefaultAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.defaultLoanAmount = amtPaid;
        this.formattedDefaultLoanAmount = amountInputted;
        return;
      }
      this.defaultLoanAmount = this.form.balances.interestBalance;
      this.formattedDefaultLoanAmount = "";
    },
    formatAndValidateMinAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.minLoanAmount = amtPaid;
        this.formattedMinLoanAmount = amountInputted;
        return;
      }
      this.minLoanAmount = this.minLoanAmount;
      this.formattedMinLoanAmount = "";
    },
    formatAndValidateMaxAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.maxLoanAmount = amtPaid;
        this.formattedMaxLoanAmount = amountInputted;
        return;
      }
      this.maxLoanAmount = this.maxLoanAmount;
      this.formattedMaxLoanAmount = "";
    },
    validateLoanAmount() {
      if (this.minLoanAmount > this.maxLoanAmount) {
        this.errorAmountMessage =
          "Minimum loan amount can not be greater than Maximum loan amount in Loan Amount Constraint";
        return;
      }
      this.errorAmountMessage = "";
    },
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
      }
    },
    validateRepaymentPeriodUnit(repaymentPeriodUnit) {
      if (repaymentPeriodUnit === "") {
        this.repaymentPeriodUnitError = true;
        this.$emit("alertWarning", "Repayment period can not be empty");
        return true;
      }
      if (repaymentPeriodUnit == 0) {
        this.repaymentPeriodUnitError = true;
        this.$emit("alertWarning", "Repayments can not be made every 0 days");
        return true;
      }
      this.repaymentPeriodUnitError = false;
      return false;
    },
    validateGracePeriod() {
      if (this.loanPenaltyGracePeriod == 0) {
        this.loanPenaltyGracePeriodError = true;
        return true;
      }
      this.loanPenaltyGracePeriodError = false;
      return false;
    },
    validateMinPenaltyRate() {
      if (this.minPenaltyRate == 0) {
        this.minPenaltyRateError = true;
        return true;
      }
      this.minPenaltyRateError = false;
      return false;
    },
    validateDefaultPenaltyRate() {
      if (this.defaultPenaltyRate == 0) {
        this.defaultPenaltyRateError = true;
        return true;
      }
      this.defaultPenaltyRateError = false;
      return false;
    },
    validateMaxPenaltyRate() {
      if (this.maxPenaltyRate == 0) {
        this.maxPenaltyRateError = true;
        return true;
      }
      this.maxPenaltyRateError = false;
      return false;
    },
    validatePenaltyCalculation(loanPenaltyCalculationMethod) {
      if (loanPenaltyCalculationMethod != 0) {
        this.validateGracePeriod();
        this.validateMinPenaltyRate();
        this.validateDefaultPenaltyRate();
        this.validateMaxPenaltyRate();
        if (
          this.validateGracePeriod() ||
          this.validateMinPenaltyRate() ||
          this.validateDefaultPenaltyRate() ||
          this.validateMaxPenaltyRate()
        )
          return true;
      }
      return false;
    },
    controlValue(prop, e) {
      let transVal = e.target.value;
      const propSplit = prop.split(".");
      if (e) {
        if (/[^0-9.]/g.test(e.target.value)) {
          transVal = e.target.value.replace(/[^0-9.]/g, "");

          if (propSplit.length == 2) {
            this.$data[propSplit[0]][propSplit[1]] = transVal;
          } else {
            this.$data[propSplit[0]] = transVal;
          }
          e.target.value = transVal;
        }
        // if (parseInt(transVal) < 1) {
        //   if (propSplit.length == 2) {
        //     this.$data[propSplit[0]][propSplit[1]] = "";
        //   } else {
        //     this.$data[propSplit[0]] = "";
        //   }
        // }
      }
    },
    save: function () {
      this.process = true;
      const copyOfPayload = JSON.parse(JSON.stringify(this.$data));

      copyOfPayload.repaymentAllocationOrder =
        copyOfPayload.repaymentAllocationOrderEnum.map((item) => item.id);

      const productFees = copyOfPayload.productFeeForms.map((item) => ({
        fee_amount: item.feeAmount,
        fee_name: item.feeName,
        fee_trigger: item.feeTrigger,
        amount_calculation_method: item.amountCalculationMethod,
        fee_application: item.feeApplication,
        active: item.active,
      }));

      copyOfPayload.productFeeForms = productFees;
      copyOfPayload.branchList = copyOfPayload.branchList.map((item) => ({
        branch_key: item.branchKey,
      }));
      delete copyOfPayload.enums;
      // const pfs = copyOfPayload.productFeeForms.map((f) => {
      //   f.types = undefined;
      //   f.payment = undefined;
      //   f.application = undefined;
      //   f.feeApplications = undefined;
      //   return f;
      // });

      //
      // copyOfPayload.productFeeForms = pfs;
      //this.validateLoanAmount();
      //if(this.errorAmountMessage.length) {
      //this.process = false;
      //this.$emit("error-creating-product", this.errorAmountMessage);
      //this.confirm = false;
      //return;
      //}
      //if(!this.errorAmountMessage.length) {
      if (this.validatePenaltyCalculation(this.loanPenaltyCalculationMethod)) {
        this.confirm = false;
        this.process = false;
        this.$emit("alertWarning", "Penalty settings can not be 0");
        return;
      }
      if (this.validateRepaymentPeriodUnit(this.repaymentPeriodUnit)) {
        this.confirm = false;
        this.process = false;
        return;
      }
      this.checkForPercentageConstraint();
      if(this.checkForPercentageConstraint()) {
        this.confirm = false;
        this.process = false;
        return;
      }
      if (!this.paymentHoliday.AllowPaymentHoliday) {
        delete copyOfPayload.paymentHoliday;
      }
      LoanApiService.post("LoanProduct/addloanproduct", copyOfPayload)
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("close");
            this.resetWindow();
            this.$emit("reload");
            this.$emit(
              "alertSuccess",
              res.message ? res.message : "Loan Product created Successfully"
            );
          } else {
            this.$emit(
              "alertError",
              res.message
                ? res.message
                : "Unable to copy loan product at the moment"
            );
          }
          //this.$emit("alertSuccess", "Loan Product created Successfully");
          //this.$emit("reload");
          // this.resetWindow();
          // this.$emit("close");
          // if (res.status) {
          //   this.$emit("alertSuccess", "Loan Product created Successfully");
          //   this.resetWindow();
          //   this.$emit("close");
          //   this.$emit("reload");
          // } else {
          //   this.$emit(
          //     "alertError",
          //     res.message
          //       ? res.message
          //       : "Unable to create loan product at the moment"
          //   );
          // }
        })
        .catch((e) => {
          this.confirm = false;
          try {
            this.process = false;
            this.$emit("alertError", e.response.data.message);
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
      //}
    },
    addFeeForm() {
      var id = this.productFeeForms.length;
      this.productFeeForms.push({
        id: id,
        types: [
          { id: 0, name: "Manual" },
          { id: 1, name: "Deducted Disbursement" },
          { id: 2, name: "Capitalized Disbursement" },
          { id: 3, name: "Upfront Disbursement" },
          { id: 4, name: "Late Repayment" },
          { id: 5, name: "Payment due (Applied Upfront" },
          { id: 6, name: "Payment due (Applied Due Date" },
        ],
        payment: [
          { id: 0, name: "Flat" },
          { id: 1, name: "% of Disbursement Amount" },
          { id: 2, name: "% of Repayment Principal Amount" },
          { id: 3, name: "Flat/Num of Installments" },
          { id: 4, name: "% of Disbursement Amount/Num of Installments" },
        ],
        application: [
          { id: 0, name: "Required" },
          { id: 1, name: "Optional" },
        ],
        feeApplications: [
          {
            id: 0,
            name: "Required",
            description: "Required",
          },
          {
            id: 1,
            name: "Optional",
            description: "Optional",
          },
        ],
        feeTrigger: "",
        feeAmount: "",
        amountCalculationMethod: 0,
        feeApplication: 0,
        feeName: "",
        active: true,
      });
    },
    removeFeeForm(id) {
      this.productFeeForms.splice(id, 1);
    },
    productFeeTypeChange(e, id) {
      const value = e.target.value;
      this.productFeeForms[id].feeTrigger = value;
      const payments = [...this.productFeePayments];

      if (value == 0) {
        this.productFeeForms[id].payment = [...payments];
        return this.productFeeForms[id].payment.splice(2, 5);
      } else if (value == 1) {
        this.productFeeForms[id].payment = [...payments];
        return this.productFeeForms[id].payment.splice(2, 5);
      } else if (value == 2) {
        this.productFeeForms[id].payment = [...payments];
        return this.productFeeForms[id].payment.splice(2, 5);
      } else if (value == 3) {
        this.productFeeForms[id].payment = [...payments];
        return this.productFeeForms[id].payment.splice(2, 5);
      } else if (value == 4) {
        this.productFeeForms[id].payment = [...payments];
        return this.productFeeForms[id].payment.splice(3, 5);
      } else if (value == 5) {
        this.productFeeForms[id].payment = [...payments];
        return this.productFeeForms[id].payment.splice(2, 1);
      } else if (value == 6) {
        this.productFeeForms[id].payment = [...payments];
        return this.productFeeForms[id].payment.splice(2, 1);
      } else {
        this.productFeeForms[id].payment = [...payments];
      }
    },
    productFeeStatusChange(e, id) {
      this.productFeeForms[id].active = e.target.checked;
    },
    productFeeNameChange(e, id) {
      this.productFeeForms[id].feeName = e.target.value;
    },
    inputCurrencyFormats(num) {
      if (num === "") return "";
      const number = (num / 1).toFixed(0).replace(",", ".");
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    productFeeAmountChange(e, id) {
      const formatAndValidateFees = (amount) => {
        const amountValue = amount;
        const amtPaid = this.convertFigureToNumber(amountValue);
        const amountInputted = this.inputCurrencyFormat(amtPaid);
        if (this.validateDepositAmount(amountValue)) {
          this.productFeeForms[id].feeAmount = Number(amtPaid);
          return amountInputted;
        }
        this.productFeeForms[id].feeAmount = "";
      };
      formatAndValidateFees(e.target.value);
    },
    productFeeApplicationChange(e, id) {
      this.productFeeForms[id].feeApplication = e.target.value;
    },
    productFeePaymentChange(e, id) {
      this.productFeeForms[id].amountCalculationMethod = e.target.value;
    },
    updateRepaymentSchedule(e) {
      if (e.target.checked) {
        this.repaymentScheduleEditOptions.push(e.target.id);
      } else {
        this.repaymentScheduleEditOptions.splice(e.target.id, 1);
      }
    },
    updateInternalControls(e) {
      if (e.target.checked) {
        this.repaymentScheduleEditOptions.push(e.target.id);
      } else {
        this.repaymentScheduleEditOptions.splice(e.target.id, 1);
      }
    },
    listBranches() {
      this.loadingBranchList = true;
      return branchService
        .get({
          branchName: null,
          branchState: "Active",
          pageIndex: 1,
          pageSize: 100,
        })
        .then((res) => {
          this.loadingBranchList = false;
          if (res.status === 200) {
            this.allBranches = res.data.data.items;
          }
        });
    },
  },
  watch: {
    interestCalculationMethod() {
      this.paymentMethod = null;
      this.gracePeriodType = null;
    },
    paymentMethod() {
      this.gracePeriodType = 0;
    },
    loanPenaltyCalculationMethod() {
      // this.minPenaltyRate = 0;
      // this.maxPenaltyRate = 0;
      // this.maxPenaltyRate = 0;
      // this.defaultPenaltyRate = 0;
      // this.loanPenaltyGracePeriod = null;
      // this.loanPenaltyCalculationMethod = null;
    },
    closeDormantAccounts() {
      this.dormancyPeriodDays = null;
    },
    LockArrearsAccounts() {
      this.lockPeriodDays = null;
    },
    CapCharges() {
      this.cappingConstraintType = 0;
      this.cappingMethod = 0;
      this.cappingPercentage = null;
      this.cappingApplyAccruedChargesBeforeLocking = false;
    },
    accountLinkagEnabled(next) {
      if (next) {
        this.linkAbleSavingsProductKey = 0;
        this.settlementOptions = 0;
      } else {
        this.linkAbleSavingsProductKey = null;
        this.settlementOptions = null;
      }
    },
    isInvestorFundsEnabled() {
      this.defaultOrganizationCommissionPercentage = 0;
      this.minOrganizationCommissionPercentage = 0;
      this.maxOrganizationCommissionPercentage = 0;
      this.adminProductSecuritySetting.funderInterestCommissionAllocationType = 0;
      this.adminProductSecuritySetting.lockFundsAtApproval = false;
    },
    forHybridGroups() {
      this.forIndividuals = false;
      this.forPureGroups = false;
    },
    forIndividuals() {
      this.forHybridGroups = false;
    },
    forPureGroups() {
      this.forHybridGroups = false;
    },
    interestApplicationMethod() {
      this.accrueLateInterest = false;
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loan.loading,
      saving: (state) => state.loan.saving,
      repaymentAreMadeEvery() {
        const self = this;
        return this.enums.repaymentAreMadeEvery.filter((unit) => {
          if (unit.id == 0 || unit.id == 1) return true;
          if ((unit.id == 2 || unit.id == 3) && self.daysInYear == 2) {
            return true;
          } else {
            return false;
          }
        });
      },
      showInstallmentsConstraints() {
        if (this.paymentMethod === 2) {
          return false;
        } else {
          return true;
        }
      },
      showGracePeriod() {
        return true;
        // if (this.interestCalculationMethod === 2 && this.paymentMethod === 0) {
        //   return true;
        // } else {
        //   return false;
        // }
      },
      isInvestorFundsEnabled() {
        return this.adminProductSecuritySetting.isInvestorFundsEnabled;
      },
    }),
    paymentMethodTypes() {
      if (this.interestCalculationMethod == 2) {
        const newPayment = this.enums.paymentMethods;
        return newPayment.splice(0, 1);
      }
      return this.enums.paymentMethods;
    },
  },
  mounted() {
    this.listBranches();
  },
};
</script>
