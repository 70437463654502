<template>
  <main class="page__content--wider">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm_status ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmStatus()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Loan Product
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmStatus()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="updateStatus()"
                >
                  {{
                    process
                      ? "Processing..."
                      : loanProductStatus
                      ? "Deactivate"
                      : "Activate"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--CONFIRM DELETE DIALOG-->
    <div
      class="modal modal__confirm show"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm_delete ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmDelete()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Loan Product
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmDelete()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="deleteItem()"
                >
                  {{ process ? "Processing..." : "Delete" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CreatePlan
      v-bind:show="showCreateModal"
      v-bind:close="closeCreateModal"
      v-bind:enums="enums"
      @close="closeCreateModal"
      @alertSuccess="alertSuccess"
      @reload="getList"
      @alertError="alertError"
    />

    <DeleteModal
      :show="userIsNotAuthorised"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Unauthorized
            </h5>
            <!-- <h5 class="text-danger">
              You don
            </h5> -->
            <div class="d-flex mt-5">

              <button
                data-dismiss="#confirmModal"
                class="
                  button
                  form__button form__button--lg
                  save-changes
                  fitrem
                "
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <EditPlan
      v-bind:show="showEditModal"
      v-bind:close="closeEditModal"
      v-bind:enums="enums"
      @close="closeEditModal"
      @alertSuccess="alertSuccess"
      @alertError="alertError"
      @reload="getList"
      v-bind:loanProduct="loanProduct"
    />

    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="reloadFilter"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      @alertError="alertError"
      :filterHeaderName="'Loan Account'"
      filterCategory="loan"
    />

    <div class="page__body">
      <div class="page__body__header">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 ml-auto">
            <div class="d-flex justify-content-between aliign-items-center">
              <div>
                <ContentHeader
                  :title="content_header.title"
                  :subTitle="content_header.subTitle"
                  :icon="false"
                  :underline="false"
                />
              </div>
              <div class="d-flex justify-content-around aliign-items-center">
                <!-- <b-dropdown
                  :html="filter_"
                  no-caret
                  class="
                    m-2
                    page__body__header__button
                    dropdown--button-grey
                    w-100
                  "
                  v-model="uiProps.branchKey"
                >
                  <b-dropdown-item
                    v-for="item in uiProps.branchList"
                    :key="item.branchKey"
                    :value="item.branchKey"
                    @click="filterByCategory(item.value, item.text)"
                    >{{ item.branchName }}</b-dropdown-item
                  >
                </b-dropdown> -->

                <!-- <b-dropdown
                  :html="filter_by_label"
                  no-caret
                  class="
                    m-2
                    page__body__header__button
                    dropdown--button-grey
                    w-100
                  "
                  v-model="sortCat"
                >
                  <b-dropdown-item
                    v-for="item in sortCategories"
                    :key="item.id"
                    v-bind:value="item.value"
                    @click="filterByAccounts(item.value, item.name)"
                    >{{ item.name }}</b-dropdown-item
                  >
                </b-dropdown> -->

                <button
                  type="button"
                  class="
                    w-100
                    button
                    page__body__header__button
                    button--grey
                    text--capital
                  "
                  @click="toggleFilterModal"
                >
                  <span class="d-inline-flex mr-2">filter</span>
                </button>

                <div class="align-self-center mx-2 dropdown">
                  <button
                    class="
                      button button--grey
                      text--capital text--small
                      d-flex
                      align-items-center
                    "
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    export
                    <span class="dropdown__icon">
                      <ion-icon name="caret-down-outline"></ion-icon>
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      href="javascript:void(0)"
                      @click="pdfDownload"
                      v-if="userCanExportToPDF"
                      class="dropdown-item text--capital"
                      >PDF</a
                    >
                    <a
                      href="javascript:void(0)"
                      @click="processDownload"
                      class="dropdown-item text--capital"
                      v-if="userCanExportToExcel"
                      >excel</a
                    >
                    <!-- <download-excel
                      class="dropdown-item text--capital"
                      style="cursor: pointer"
                      :data="download"
                      :fields="json_fields"
                      name="loans.xls"
                    >
                      excel
                    </download-excel> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display:flex; width:50%; font-size:14px" v-if="filterText">
        <div style="width:80%">
          {{ filterText }}
        </div>
        <div style="display:flex;width:10%">
          <span style="cursor:pointer;font-size:17px" @click="editFilter">
            <i style="padding-right:5px" class="bi bi-pencil-square"></i>
          </span>
          <span style="cursor:pointer;font-size:17px" @click="deleteFilter">
            <i style="padding-left:5px" class="bi bi-x-circle-fill"></i>
          </span>
        </div>
      </div>
      <div class="page__body w-100" style="overflow-x: scroll">
        <table class="customtable _customtable">
          <thead>
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                v-for="(item, index) in columns"
                v-bind:key="index"
                v-bind:title="item.title"
              >
                {{ item.title }}
              </th>
            </tr>
          </thead>
          <tbody v-if="loading">
            <tr>
              <td colspan="7">
                <div class="d-flex align-items-center justify-content-center p5">
                  <div
                    class="spinner-border"
                    style="width: 1rem; height: 1rem"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span
                    class="text--black text--600 text--medium d-inline-flex ml-4"
                    >Please wait</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="accounts && !userIsNotAuthorised">
            <tr v-for="(row, i) in accounts.data" v-bind:key="i">
              <td>
                <span
                  class="
                    table__body__text table__text
                    text--capital text--primary
                    cursor-pointer
                  "
                  style="padding-right: 15px; word-break: break-word;"
                  @click="viewDetails(row.loanAccountKey)"
                >
                  {{ row.loanName }}
                </span>
              </td>
              <td>
                <span
                  class="table__body__text table__text text--capital"
                  style="padding-right: 15px; word-break: break-word;"
                >
                  {{ row.productType }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ row.unique_loan_id }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ row.assignedBranchName || row.branchName }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{
                    getCustomerFullName(row.customerInformationDetails.data)
                  }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital" style="word-break: break-word;">
                  {{
                    row ? getState(row.account_sub_state) ? (getState(row.accountState) + '(' + getState(row.account_sub_state) + ')' ) : getState(row.accountState) : "NA"
                  }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ row.loanAmount | currency }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ 
                    row.accountOfficer
                      ? row.accountOfficer.user_first_name + ' ' + 
                        row.accountOfficer.user_last_name
                      : ""
                  }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ row.lastModifiedDate | moment("Do-MMM-YYYY") }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-if="userIsNotAuthorised">
            <tr>
              <td colspan="12">
                <div
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    p5
                  "
                >
                  <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                  <span
                    class="d-block text--danger text--600 text--medium mt-3 mb-5"
                    >{{ error }}</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination" v-if="accounts && !loading && !userIsNotAuthorised">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <span class="d-inline-block text--primary mr-3">
              <label for="">
                <select
                  v-model.number="size"
                  class="select select--lg select--page"
                  @change="getAccounts(size)"
                >
                  <option
                    v-for="psize in pagesizes"
                    :key="psize.id"
                    :id="psize.id"
                    :selected="size"
                  >
                    {{ psize.size }}
                  </option>
                </select>
              </label>
            </span>
            <span class="d-inline-block text--primary">{{
              products ? accounts.currentPage : 0
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              products ? accounts.totalPages : 0
            }}</span>
            <!--of
            <span>{{ accounts ? accounts.totalCount : 0 }}</span>-->
          </div>
          <div
            class="
              pagination__buttons
              d-flex
              align-items-center
              justify-content-between
            "
            v-if="accounts"
          >
            <button
              class="button button--sm text--regular"
              @click="changePage('previous')"
              v-if="accounts.hasPrevious || accounts.hasPreviousPage"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>

            <div v-if="!isLargeSizePaged" class="d-flex align-items-center pagination__buttons">
              <button
                v-for="page in accounts.totalPages"
                :key="page"
                :class="{
                  'text--white button--primary ': accounts.currentPage === page,
                }"
                class="button text--regular button--sm ml-2"
                @click="changePage(page)"
              >
                {{ page }}
              </button>
            </div>
            
            <div v-else class="text--capital text--regular pagination__text">
              Page
              <span class="d-inline-block text--primary">{{
                accounts.currentPage
              }}</span>
              of
              <span class="d-inline-block text--primary">{{
                accounts.totalPages
              }}</span>
              from <span class="d-inline-block text--primary">{{ accounts.totalCount }}</span> Records
            </div>

            <button
              class="button text--regular button--sm ml-2"
              @click="changePage('next')"
              v-if="accounts.hasNext || accounts.hasNextPage"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// import JsonExcel from "vue-json-excel";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { mapState } from "vuex";
import CreatePlan from "./create";
import EditPlan from "./edit";
import {
  GET_LOAN_PRODUCTS,
  GET_LOAN_ACCOUNTS,
  GET_LOAN_EXPORTS,
} from "../../../core/services/store/types";
import LoanApiService from "../../../core/services/api.loan.service";
import branchService from "@/core/services/organization-service/branches.service";
import { Role } from "@/core/services/role.js";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
export default {
  name: "LaonsIndex",
  components: {
    // downloadExcel: JsonExcel,
    CreatePlan: CreatePlan,
    EditPlan: EditPlan,
    DeleteModal,
    CreateFilter: () => 
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      )
  },
  data: function () {
    return {
      uiProps: {
        exportOptions: ["PDF", "Excel"],
        loadingBranchList: false,
        branchList: [],
        branchKey: null,
      },
      page: 1,
      size: 20,
      status: true,
      category: null,
      sorted: true,
      sortCat: "",
      confirm_status: false,
      confirm_delete: false,
      loanProduct: null,
      loanProductKey: "",
      loanProductStatus: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Loan Product has been created",
      },
      filter_by_label: "Filter <ion-icon name='caret-down-outline'/>",
      process: false,
      showCreateModal: false,
      showEditModal: false,
      filter_by: "deactivated",
      filter_: "All Branches <ion-icon name='caret-down-outline'/>",
      export_: "Export <ion-icon name='caret-down-outline'/>",
      content_header: {
        title: "Loan Accounts",
        subTitle: "",
      },
      accountError: false,
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      columns: [
        {
          title: "Display Name",
        },
        {
          title: "Product",
        },
        {
          title: "ID",
        },
        {
          title: "Branch",
        },
        {
          title: "Customer Name",
        },
        {
          title: "Account State",
        },
        {
          title: "Amount",
        },
        {
          title: "Account Officer",
        },
        {
          title: "Last Modified Date",
        },
      ],
      sortCategories: [
        {
          id: 0,
          name: "Product Name",
          description: "loanname",
          value: "loan_name",
        },
        {
          id: 1,
          name: "Account State",
          description: "producttype",
          value: "account_state",
        },
        {
          id: 2,
          name: "Creation Date",
          description: "creationdate",
          value: "creation_date",
        },
      ],
      json_fields: {
        "Loan Name": "loan_name",
        "Customer Name": "name",
        "Approved State": "state",
        Amount: "amount",
        "Principal Due": "prin_due",
        "Penalty Due": "pen_due",
        "Fees Paid": "paid",
        "Last Modified Date": "date_mod",
      },
      filters: [],
      showFilterModal: false,
      fetchAccount: {
        filters: [],
        pageIndex: 1,
        pageSize: 10,
        isFromFilter: false,
        filterCategory: "",
      },
      exportData: [],
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
    };
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    getState(state) {
      if (state) return state.replace(/_/g, ' ').toLowerCase();
      return '';
    },
    getCustomerFullName(customer) {
      if (customer?.customerMiddleName) return `${customer.customerFirstName} ${customer.customerMiddleName} ${customer.customerLastName}`
      if (customer?.customerLastName) return `${customer.customerFirstName} ${customer.customerLastName}`;
      if (customer?.customerFirstName) return customer.customerFirstName;
      return '';
    },
    sendFilter(value) {
      const filterArray = value.map(obj => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === '' || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      // this.filters = filterArray;
      this.fetchAccount.filters = filterArray;
      this.fetchAccount.pageIndex = 1;
      this.fetchAccount.pageSize = this.size;
      this.fetchAccount.filterCategory = "loan";
      this.fetchAccount.isFromFilter = true;
      this.$store
        .dispatch(GET_LOAN_ACCOUNTS, this.fetchAccount)
        .then(() => {
          this.alertSuccess("Successfully applied filter");
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        })
      this.getExportData();
    },
    reloadFilter() {
      this.deleteFilter()
      this.fetchAccount.isFromFilter = false;
      this.fetchAccount.filterCategory = "";
      this.size = 20;
      this.fetchAccount.filters = [];
      this.fetchAccount.pageIndex = 1;
      this.getAccounts();
    },
    getExportData() {
      if (this.accounts.totalCount > 0) {
        this.fetchAccount.pageSize = this.accounts.totalCount;
        this.$store
          .dispatch(GET_LOAN_EXPORTS, this.fetchAccount)
          .then(() => {
            this.alertSuccess("Successfully applied filter");
          })
          .catch((error) => {
            this.alertError(error.response.data.message);
          });
        this.fetchAccount.pageSize = 10;
      }
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = []
    },
    editFilter() {
      this.showEditedValue = Math.random()
      this.toggleFilterModal();
    },
    filterByAccounts(id, name) {
      this.sorted = false;
      this.category = id;
      this.filter_by_label = name + " <ion-icon name='caret-down-outline'/>";
      this.$store.dispatch(GET_LOAN_ACCOUNTS, {
        page: this.products.currentPage,
        size: this.size,
        sortColumn: id,
        sort: this.sorted,
      });
    },
    toggleStatus(e) {
      this.status = e.target.checked ? true : false;
      // if (e.target.checked)
      this.$store.dispatch(GET_LOAN_PRODUCTS, {
        page: this.products.currentPage,
        size: this.size,
        activestatus: e.target.checked ? false : true,
        category: this.category,
      });
    },
    getList(page = null) {
      this.$store.dispatch(GET_LOAN_PRODUCTS, {
        page: page ? page : this.page,
        size: this.size,
        activestatus: this.status,
        category: this.category,
      });
    },
    listBranches() {
      const self = this;
      this.uiProps.loadingBranchList = true;
      branchService
        .get({
          branchName: null,
          branchState: "Active",
          pageIndex: 1,
          pageSize: 100,
          sortColumn: "",
          isDescending: false,
        })
        .then((res) => {
          self.uiProps.loadingBranchList = false;
          if (res.status === 200) {
            self.uiProps.branchList = res.data.data.items;
          }
        })
        .catch(() => {
          self.uiProps.loadingBranchList = false;
        });
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Loans List Download",
        Subject: "Loans List Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("LoansList");

      let sheetData = [[
        "Display Name",
        "Product",
        "Customer Name",
        "Approved State",
        "Amount",
        "Principal Due",
        "Penalty Due",
        "Fees Paid",
        "Last Modified Date",
      ]];

      if (this.loanExports && this.loanExports.data) {
        this.loanExports.data.map((item) => {
          let new_arr = [];
          new_arr.push(item.loanName);
          new_arr.push(item.productType);
          new_arr.push(
            item.customerInformationDetails &&
            item.customerInformationDetails.data
              ? `${item.customerInformationDetails.data.customerFirstName} ${item.customerInformationDetails.data.customerLastName}`
              : ""
          );
          new_arr.push(item.accountState);
          new_arr.push(parseFloat(item.loanAmount).toFixed(2));
          new_arr.push(parseFloat(item.principalDue).toFixed(2));
          new_arr.push(parseFloat(item.penaltyDue).toFixed(2));
          new_arr.push(parseFloat(item.feesPaid).toFixed(2));
          new_arr.push(
            item.lastModifiedDate
              ? moment(item.lastModifiedDate).format("DD-MM-YYYY")
              : ""
          );

          sheetData.push(new_arr);
        });
      }

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["LoansList"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "loans.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Display Name", dataKey: "loan_name" },
        { title: "Product", dataKey: "product" },
        { title: "Customer Name", dataKey: "name" },
        { title: "Approved State", dataKey: "state" },
        { title: "Amount", dataKey: "amount" },
        { title: "Principal Due", dataKey: "prin_due" },
        { title: "Penalty Due", dataKey: "pen_due" },
        { title: "Fees Paid", dataKey: "paid" },
        { title: "Last Modified Date", dataKey: "date_mod" },
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("loans.pdf");
    },
    getAccounts(page = null) {
      this.fetchAccount.pageSize = page ? page : this.size;
      this.$store.dispatch(GET_LOAN_ACCOUNTS, this.fetchAccount);
      if(this.userIsNotAuthorised) return this.alertWarning('You do not have permission to view this page')
    },
    changePage(page = null) {
      if (page === "next") {
        this.fetchAccount.pageIndex++;
        this.fetchAccount.pageSize = this.size;
        this.$store.dispatch(GET_LOAN_ACCOUNTS, this.fetchAccount);
      } else if (page === "previous") {
        this.fetchAccount.pageIndex--;
        this.fetchAccount.pageSize = this.size;
        this.$store.dispatch(GET_LOAN_ACCOUNTS, this.fetchAccount);
      } else if (page !== null) {
        this.fetchAccount.pageIndex = page;
        this.fetchAccount.pageSize = this.size;
        this.$store.dispatch(GET_LOAN_ACCOUNTS, this.fetchAccount);
      }
    },
    confirmStatus(row = null) {
      if (row) this.loanProductKey = row.loanProductKey;
      this.loanProductStatus = row.activated;

      this.alert.show = false;
      if (!this.confirm_status) {
        this.confirm_status = true;
      } else {
        this.confirm_status = false;
      }
    },
    confirmDelete(row) {
      if (row) this.loanProductKey = row.loanProductKey;
      this.loanProductStatus = row.activated;
      this.alert.show = false;
      if (!this.confirm_delete) {
        this.confirm_delete = true;
      } else {
        this.confirm_delete = false;
      }
    },
    updateStatus() {
      const payload = {
        loanProductKey: this.loanProductKey,
        userId: "string",
      };
      const action = this.loanProductStatus
        ? "LoanProduct/deactivateloanproduct"
        : "LoanProduct/activateloanproduct";
      this.process = true;
      LoanApiService.post(action, payload)
        .then((res) => {
          this.process = false;
          this.confirm_status = false;
          if (res.data.status) {
            this.getList();
            this.alertSuccess(res.data.message);
          } else {
            this.alertError(res.data.message);
          }
        })
        .catch((e) => {
          this.confirm_status = false;
          try {
            this.process = false;
            this.alertError(e.response.data.message);
          } catch (e) {
            this.alertError(
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    deleteItem() {
      const payload = {
        loanProductKey: this.loanProductKey,
        userId: "string",
      };
      const action = "LoanProduct/deleteloanproduct";
      this.process = true;
      LoanApiService.post(action, payload)
        .then((res) => {
          this.process = false;
          this.confirm_delete = false;
          if (res.data.status) {
            this.getList();
            this.alertSuccess(res.data.message);
          } else {
            this.alertError(res.data.message);
          }
        })
        .catch((e) => {
          this.confirm_delete = false;
          try {
            this.process = false;
            this.alertError(e.response.data.message);
          } catch (e) {
            this.alertError(
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    goToDashboard() {
      this.$router.push({ path: '/dashboard' });
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    openEditModal(row = null) {
      this.loanProduct = row;
      this.showEditModal = true;
    },
    closeCreateModal() {
      this.showCreateModal = false;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    filterByCategory(id, name) {
      this.filter_by_label = name + " <ion-icon name='caret-down-outline'/>";
      this.$store.dispatch(GET_LOAN_PRODUCTS, {
        page: this.products.currentPage,
        size: this.size,
        activestatus: false,
        category: id,
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:MM A");
      }
    },
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      return "₦" + val;
    },
    getFullName(firstName, lastName) {
      return firstName + lastName;
    },
    viewDetails(key) {
      this.$router.push({ path: `/loan/accounts/${key}` });
    },
  },
  watch: {
    category: function (val) {
      this.filter_ = val + " <ion-icon name='caret-down-outline'/>";
      this.$store.dispatch(GET_LOAN_PRODUCTS, {
        page: this.products.currentPage,
        size: this.size,
        activestatus: false,
        category: val,
      });
    },
    loanExports(){
      this.filterText = this.loanExports.filterText ? this.loanExports.filterText.replace(/_/g, " ") : "";
      this.filterEdit = this.loanExports.filters ? this.loanExports.filters : [];
    },
    accounts() {
      this.filterText = this.accounts.filterText ? this.accounts.filterText.replace(/_/g, " ") : "";
      this.filterEdit = this.accounts.filters ? this.accounts.filters : [];
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      products: (state) => state.loan.products,
      accounts: (state) => state.loan.accounts,
      loanExports: (state) => state.loan.loanExports,
      enums: (state) => state.loan.enums,
      error: (state) => state.loan.error,
      errorCode: (state) => state.loan.errorCode,
      categories: (state) =>
        state.loan.enums.productCategory.map((item, i) => {
          if (i === 0) {
            return { value: null, text: "All" };
          } else {
            return { value: item.id, text: item.name };
          }
        }),
      loading: (state) => state.loan.loading_accounts,
      saving: (state) => state.loan.saving,
    }),
    download() {
      let data = [];
      this.loanExports.data.map((item) => {
        let new_obj = {};
        new_obj.loan_name = item.loanName;
        new_obj.product = item.productType;
        new_obj.name =
          item.customerInformationDetails &&
          item.customerInformationDetails.data
            ? `${item.customerInformationDetails.data.customerFirstName} ${item.customerInformationDetails.data.customerLastName}`
            : "";
        new_obj.state = item.accountState;
        new_obj.amount = parseFloat(item.loanAmount).toFixed(2);
        new_obj.prin_due = parseFloat(item.principalDue).toFixed(2);
        new_obj.pen_due = parseFloat(item.penaltyDue).toFixed(2);
        new_obj.paid = parseFloat(item.feesPaid).toFixed(2);
        new_obj.date_mod = item.lastModifiedDate
          ? moment(item.lastModifiedDate).format("DD-MM-YYYY")
          : "";

        data.push(new_obj);
      });

      return data;
    },
    userCanViewLoanAccount() {
      const account = this.user.scopes.find(item => item.scopeName === Role.LoanAccounts);
      const { permissionsList } = account;
      return permissionsList.CanViewLoanAccount;
    },
    userCanExportToExcel() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
    userIsNotAuthorised() {
      return !this.userCanViewLoanAccount;
    },
    isLargeSizePaged() {
      return this.accounts?.totalPages > 10;
    }
  },
  mounted() {
    this.getAccounts();
    this.listBranches();
  },
};
</script>
<style lang="scss" scoped>
._customtable {
  width: 100%;
}

._customtable thead th:first-of-type {
  width: 195px;
}

.customtable td {
  padding-right: 15px !important;
}
</style>
