const product_enums = require("./products_enums");
export const CreateLoanAccountInitialState = function () {
  return {
    enums: product_enums,
    allUsers: [],
    allActiveGroups: [],
    editBankDetails: false,
    nameError: 'Account verification failed',
    formattedLoanAmount: '',
    form: {
      assignedBranchKey: null,
      selectedCustomer: null,
      accountState: "PENDING APPROVAL",
      accountHolderType: "CLIENT",
      customInformation: [],
      assignedUserKey: null,
    },
    openManualApprovalModal: false,
    openApproveDisbursement: false,
    accountOfficers: [],
    allEmployers: [],
    employer: {},
    employerKey: "",
    sources: [],
    assets: [],
    isValidating: false,
    payOffRate: 0,
    uiProps: {
      fieldFetchModel: {
        customFieldSetKey: "ec3794533518469692d8929e5708e8c7",
        pageIndex: 1,
        pageSize: 100,
        entityLinkedKey: "8f638a0f02e44aacbd2c776897c4c365",
      },
      loadingCustomFieldSets: false,
      editField: false,
      customFieldSets: [],
      customFieldSet: null,
      errors: {
        collateral: { valid: true, message: "" },
        guarantees: { valid: true, message: "" },
        arrearsToleranceAmountError: { valid: true, message: "" },
        arrearsTolerancePeriodError: { valid: true, message: "" },
      },
    },
    actualObject: {
      savingsAccountKey: "",
      type: "",
      guarantorKey: "",
      guarantorType: "",
      amount: "",
      description: "",
      assetName: "",
    },
    assignedBranchKey: null,
    arrearsTolerancePeriod: null,
    arrearsToleranceAmount: null,
    repaymentInstallments: null,
    repaymentPeriodCount: null,
    repaymentPeriodUnit: null,
    confirm: false,
    showInactiveFees: true,
    process: false,
    productTypeKey: null,
    minLoanAmount: null,
    maxLoanAmount: null,
    whatPage: "general",
    branchList: [],
    customers: [],
    scheduleReady: false,
    loadingSchedule: false,
    loadingCustomers: false,
    loadingGuarantorAccounts: false,
    generatingSchedule: false,
    guarantorAccounts: null,
    accountHolderKey: null,
    alert: {
      show: false,
      status: "",
      title: "Success",
      description: "Loan Product has been created",
    },
    disbursementDetails: {
      anticipatedDisbursementDate: null,
      firstRepaymentDate: null,
      bankKey: null,
      bankAccountNumber: null,
      bankAccountName: null,
    },
    bank: {},
    accountArrearsSettings: {
      dateCalculationMethod: "",
      monthlyToleranceDay: 0,
      nonWorkingDaysMethod: "",
      toleranceCalculationMethod: "",
      toleranceFloorAmount: 0,
      tolerancePercentageOfOutstandingPrincipal: null,
      tolerancePeriod: 0,
    },
    guarantees: [],
    repayments: [],
    collateralAssets: [],
    guaranteeModel: {
      source: null,
      account: null,
      amount: null,
      accountList: [],
      sourceList: [],
      loadingSource: false,
      loadingAccounts: false,
      errorAccount: false,
      errorAmount: false,
      errorSource: false,
    },
    collateralModel: {
      assetName: null,
      description: null,
      amount: null,
      errorDescription: false,
      errorAssetName: false,
      errorAmount: false,
    },
    scheduleGenerationError: null,
    defaultFirstRepaymentDueDateOffset: 0,
    gracePeriod: 0,
    gracePeriodType: "",
    interestCalculationMethod: "",
    interestRate: null,
    interestChargeFrequency: "NA",
    loanAmount: 0,
    interestType: null,
    loanName: "",
    notes: "",
    selectedAccountDetails: {},
    loanKey:"",
    validateAmounts: false,
    repaymentReschedulingMethod: null,
    repaymentScheduleMethod: null,
    loanFees: {},
    newLoanFees: [],
    filteredLoanFee: [],
    newFees: [],
  };
};

export const EditLoanAccountInitialState = function () {
  return {
    isLoading: false,
    reschedulePaymentNumber: "",
    enums: product_enums,
    bankSortedCode: '',
    formattedLoanAmount: '',
    nameError: 'Account verification failed',
    isValidating: false,
    allEmployers: [],
    employer: {},
    employerKey: "",
    payOffRate: 0,
    form: {
      assignedBranchKey: null,
      selectedCustomer: null,
      accountState: "",
      accountHolderType: "CLIENT",
      // customInformation: [],
      assignedUserKey: null,
    },
    accountOfficers: [],
    uiProps: {
      fieldFetchModel: {
        customFieldSetKey: "ec3794533518469692d8929e5708e8c7",
        pageIndex: 1,
        pageSize: 100,
        entityLinkedKey: "8f638a0f02e44aacbd2c776897c4c365",
      },
      loadingCustomFieldSets: false,
      customFieldSets: [],
      customFieldSet: null,
      errors: {
        collateral: { valid: true, message: "" },
        guarantees: { valid: true, message: "" },
        loanAmount: { valid: true, message: "" },
      },
    },
    customer: null,
    assignedBranchKey: null,
    arrearsTolerancePeriod: null,
    arrearsToleranceAmount: null,
    repaymentInstallments: null,
    repaymentPeriodCount: null,
    repaymentPeriodUnit: null,
    confirm: false,
    showInactiveFees: true,
    process: false,
    productTypeKey: null,
    minLoanAmount: null,
    maxLoanAmount: null,
    whatPage: "customer",
    branchList: [],
    customers: [],
    scheduleReady: false,
    loadingSchedule: false,
    loadingCustomers: false,
    loadingGuarantorAccounts: false,
    generatingSchedule: false,
    guarantorAccounts: null,
    accountHolderKey: null,
    alert: {
      show: false,
      status: "",
      title: "Success",
      description: "Loan Product has been created",
    },
    disbursementDetails: {
      anticipatedDisbursementDate: new Date(),
      firstRepaymentDate: null,
      bankKey: null,
      bankAccountNumber: null,
      bankAccountName: null,
    },
    bank: {},
    accountArrearsSettings: {
      dateCalculationMethod: "",
      monthlyToleranceDay: 0,
      nonWorkingDaysMethod: "",
      toleranceCalculationMethod: "",
      toleranceFloorAmount: 0,
      tolerancePercentageOfOutstandingPrincipal: null,
      tolerancePeriod: 0,
    },
    guarantees: [],
    assets: [],
    repayments: [],
    collateralAssets: [],
    guaranteeModel: {
      source: null,
      account: null,
      amount: null,
      accountList: [],
      sourceList: [],
      loadingSource: false,
      loadingAccounts: false,
      errorAccount: false,
      errorAmount: false,
      errorSource: false,
    },
    collateralModel: {
      description: null,
      amount: null,
      assetName: null,
      errorDescription: false,
      errorAmount: false,
      errorAssetName: false,
    },
    scheduleGenerationError: null,
    defaultFirstRepaymentDueDateOffset: 0,
    gracePeriod: 0,
    gracePeriodType: "",
    interestCalculationMethod: "",
    interestRate: null,
    loanProduct: null,
    interestChargeFrequency: "NA",
    loanAmount: 0,
    loanName: "",
    repaymentReschedulingMethod: "",
    repaymentScheduleMethod: "",
    notes: "",
    allCustomers: [],
    loanFees: {},
    newLoanFees: [],
    filteredLoanFee: [],
    newFees: [],
  };
};

export const CreateLoanProductInitialState = function () {
  return {
    errorAmountMessage: "",
    enums: product_enums,
    confirm: false,
    showInactiveFees: true,
    formattedDefaultLoanAmount: '',
    formattedMinLoanAmount: '',
    formattedMaxLoanAmount: '',
    process: false,
    closeDormantAccounts: false,
    LockArrearsAccounts: false,
    CapCharges: false,
    loanProductKey: "",
    accountingMethod: "",
    adminPredefinedFees: [],
    accountInitialState: 0,
    accountLinkagEnabled: false,
    accrueLateInterest: false,
    activated: false,
    allowArbitrayFees: false,
    allowCustomRepaymentAllocation: false,
    amortizationMethod: "NA",
    applyInterestOnRepaymentMethod: "NA",
    branchList: [],
    loadingBranchList: false,
    productArrearsSettingsKey: "NA",
    autoCreateLinkedAccounts: false,
    autoLinkAccounts: false,
    cappingApplyAccruedChargesBeforeLocking: false,
    cappingConstraintType: 0,
    cappingMethod: 0,
    cappingPercentage: 0,
    category: 0,
    daysInYear: 2,
    defaultFirstRepaymentDueDateOffset: null,
    defaultOrganizationCommissionPercentage: null,
    minOrganizationCommissionPercentage: null,
    maxOrganizationCommissionPercentage: null,
    defaultNumInstallments: null,
    defaultGracePeriod: "",
    defaultLoanAmount: null,
    defaultPenaltyRate: 0,
    defaultPrincipalRepaymentInterval: 1,
    defaultRepaymentPeriodCount: 0,
    dormancyPeriodDays: null,
    elementsRecalculationMethod: "NA",
    forAllBranches: true,
    allBranches: [],
    forHybridGroups: false,
    forIndividuals: true,
    forPureGroups: false,
    futurePaymentsAcceptance: 0,
    fixedDaysOfMonth: "NA",
    gracePeriodType: 0,
    userDefinedLoanId: "",
    idGeneratorType: 1,
    idPattern: null,
    interestAccrualCalculation: 1,
    interestAccruedAccountingMethod: 0,
    interestApplicationMethod: 0,
    interestBalanceCalculationMethod: "NA",
    interestCalculationMethod: 0,
    interestProductSettingsKey: "NA",
    interestType: '',
    isCollateralEnabled: false,
    isGuarantorsEnabled: false,
    latePaymentsRecalculationMethod: "NA",
    lineOfCreditRequirement: 2,
    linkAbleSavingsProductKey: null,
    loanPenaltyCalculationMethod: 0,
    loanPenaltyGracePeriod: 0,
    loanProductType: "fixed_term_loan",
    lockPeriodDays: 0,
    maxFirstRepaymentDueDateOffset: null,
    maxGracePeriod: "",
    maxLoanAmount: null,
    maxNumberOfDisbursementTranches: 0,
    maxNumInstallments: null,
    maxPenaltyRate: 0,
    minFirstRepaymentDueDateOffset: null,
    minGracePeriod: "",
    minLoanAmount: null,
    minNumInstallments: null,
    minPenaltyRate: 0,
    offSetPercentage: 0,
    paymentMethod: null,
    prepaymentAcceptance: 0,
    prepaymentRecalculationMethod: "NA",
    principalPaidInstallmentStatus: "NA",
    principalPaymentSettingsKey: "NA",
    productDescription: "",
    productName: "",
    productSecuritySettingsKey: "",
    redrawSettingsKey: "NA",
    repaymentAllocationOrder: [],
    repaymentCurrencyRounding: 0,
    repaymentElementsRoundingMethod: "NA",
    repaymentPeriodUnit: "",
    repaymentPeriodUnitError: false,
    repaymentReschedulingMethod: 1,
    // repaymentScheduleEditOptions: [],
    repaymentScheduleMethod: 0,
    payOffRate: null,
    roundingRepaymentScheduleMethod: 1,
    scheduleDueDatesMethod: "NA",
    scheduleInterestDaysCountMethod: "NA",
    settlementOptions: null,
    shortMonthHandlingMethod: "NA",
    taxCalculationMethod: "NA",
    taxesOnFeesEnabled: false,
    taxesOnInterestEnabled: false,
    taxesOnPenaltyEnabled: false,
    taxSourceKey: "NA",
    internalControls: [],
    productFeeForms: [],
    repaymentAllocationOrderEnum: [
      {
        id: 0,
        name: "Fee",
        description: "Fee",
      },
      {
        id: 1,
        name: "Penalty",
        description: "Penalty",
      },
      {
        id: 2,
        name: "Interest",
        description: "Interest",
      },
      {
        id: 3,
        name: "Principal",
        description: "Principal",
      },
    ],
    repaymentScheduleEditOptions: {
      AdjustPaymentDates: false,
      AdjustPrincipalPayment: false,
      AdjustInterestPayment: false,
      AdjustFeePayment: false,
      AdjustPenaltyPayment: false,
    },
    adminArrearsResponseModel: {
      defaultTolerancePercentageOfOutstandingPrincipal: null,
      defaultTolerancePeriod: null,
      maxTolerancePercentageOfOutstandingPrincipal: null,
      maxTolerancePeriod: null,
      minTolerancePercentageOfOutstandingPrincipal: null,
      minTolerancePeriod: null,
      monthlyToleranceDsay: 1,
    },
    generalArrearsSetting: {
      toleranceFloorAmount: null,
    },
    adminProductSecuritySetting: {
      funderInterestCommissionAllocationType: 0,
      isCollateralEnabled: false,
      isGuarantorsEnabled: false,
      isInvestorFundsEnabled: false,
      lockFundsAtApproval: false,
      requiredGuaranties: '',
      requiredInvestorFunds: 0,
    },
    adminInterestProductSettings: {
      interestProductSettingsKey: "NA",
      allowNegativeInterestRate: 0,
      defaultInterestRate: null,
      interestRateCeilingValue: 0,
      interestRateFloorValue: 0,
      maxInterestRate: null,
      minInterestRate: null,
    },
    adminPrincipalPaymentproductSetting: {
      principalPaymentProductSettingsKey: "NA",
      defaultAmount: 0,
      defaultPercentage: 0,
      maxAmount: 0,
      maxPercentage: 0,
      minAmount: 0,
      minPercentage: 0,
    },
    adminProductRedrawSetting: {
      productRedrawSettingsKey: "NA",
      allowRedraw: false,
    },
    adminIndexRateSource: {
      rateSourceName: "NA",
      rateSourceNotes: "NA",
      rateSourceType: "NA",
    },
    allowAAutoDisbursement: false,
    allowUnderwriterOveride: false,
    requireOfferAcceptance: false,
    loanPenaltyGracePeriodError: false,
    defaultPenaltyRateError: false,
    minPenaltyRateError: false,
    maxPenaltyRateError: false,
    productFeePayments: [
      { id: 0, name: "Flat" },
      { id: 1, name: "% of Disbursement Amount" },
      { id: 2, name: "% of Repayment Principal Amount" },
      { id: 3, name: "Flat/Num of Installments" },
      { id: 4, name: "% of Disbursement Amount/Num of Installments" },
    ],
    paymentHoliday: {
      AllowPaymentHoliday: false,
      IsPercentageType: false,
      IsCustomType: false,
      IsFlatType: false,
      FeeAmount: 0,
      MaximumPaymentNumber: 0,
      FeePercentage: 0
    },
  };
};
export const EditLoanProductInitialState = function () {
  return {
    errorAmountMessage: "",
    enums: product_enums,
    formattedDefaultLoanAmount: '',
    formattedMinLoanAmount: '',
    formattedMaxLoanAmount: '',
    confirm: false,
    showInactiveFees: true,
    process: false,
    closeDormantAccounts: false,
    LockArrearsAccounts: false,
    CapCharges: false,
    loanProductKey: "",
    accountingMethod: "",
    adminPredefinedFees: [],
    accountInitialState: 0,
    payOffRate: null,
    accountLinkagEnabled: false,
    accrueLateInterest: false,
    activated: false,
    allowArbitrayFees: false,
    allowCustomRepaymentAllocation: false,
    amortizationMethod: "NA",
    applyInterestOnRepaymentMethod: "NA",
    branchList: [],
    loadingBranchList: false,
    productArrearsSettingsKey: "NA",
    autoCreateLinkedAccounts: false,
    autoLinkAccounts: false,
    cappingApplyAccruedChargesBeforeLocking: false,
    cappingConstraintType: 0,
    cappingMethod: 0,
    cappingPercentage: 0,
    category: 0,
    daysInYear: 2,
    defaultFirstRepaymentDueDateOffset: null,
    defaultOrganizationCommissionPercentage: null,
    minOrganizationCommissionPercentage: null,
    maxOrganizationCommissionPercentage: null,
    defaultNumInstallments: null,
    defaultGracePeriod: "",
    defaultLoanAmount: null,
    defaultPenaltyRate: 0,
    defaultPrincipalRepaymentInterval: 1,
    defaultRepaymentPeriodCount: 0,
    dormancyPeriodDays: null,
    elementsRecalculationMethod: "NA",
    forAllBranches: true,
    allBranches: [],
    forHybridGroups: false,
    forIndividuals: true,
    forPureGroups: false,
    futurePaymentsAcceptance: 0,
    fixedDaysOfMonth: "NA",
    gracePeriodType: 0,
    userDefinedLoanId: "",
    idGeneratorType: 1,
    idPattern: "@@@@###",
    interestAccrualCalculation: 1,
    interestAccruedAccountingMethod: 0,
    interestApplicationMethod: 0,
    interestBalanceCalculationMethod: "NA",
    interestCalculationMethod: 0,
    interestProductSettingsKey: "NA",
    interestType: 0,
    isCollateralEnabled: false,
    isGuarantorsEnabled: false,
    latePaymentsRecalculationMethod: "NA",
    lineOfCreditRequirement: 2,
    linkAbleSavingsProductKey: null,
    loanPenaltyCalculationMethod: 0,
    loanPenaltyGracePeriod: 0,
    loanProductType: "fixed_term_loan",
    lockPeriodDays: 0,
    maxFirstRepaymentDueDateOffset: null,
    maxGracePeriod: "",
    maxLoanAmount: null,
    maxNumberOfDisbursementTranches: 0,
    maxNumInstallments: null,
    maxPenaltyRate: 0,
    minFirstRepaymentDueDateOffset: null,
    minGracePeriod: "",
    minLoanAmount: null,
    minNumInstallments: null,
    minPenaltyRate: 0,
    offSetPercentage: 0,
    paymentMethod: 0,
    prepaymentAcceptance: 0,
    prepaymentRecalculationMethod: "NA",
    principalPaidInstallmentStatus: "NA",
    principalPaymentSettingsKey: "NA",
    productDescription: "",
    productName: "",
    productSecuritySettingsKey: "",
    redrawSettingsKey: "NA",
    repaymentAllocationOrder: [],
    repaymentCurrencyRounding: 0,
    repaymentElementsRoundingMethod: "NA",
    repaymentPeriodUnit: "",
    repaymentReschedulingMethod: 1,
    // repaymentScheduleEditOptions: [],
    repaymentScheduleEditOptions: {
      AdjustPaymentDates: false,
      AdjustPrincipalPayment: false,
      AdjustInterestPayment: false,
      AdjustFeePayment: false,
      AdjustPenaltyPayment: false,
    },
    generalArrearsSetting: {
      toleranceFloorAmount: null,
    },
    repaymentScheduleMethod: 0,
    roundingRepaymentScheduleMethod: 1,
    scheduleDueDatesMethod: "NA",
    scheduleInterestDaysCountMethod: "NA",
    settlementOptions: null,
    shortMonthHandlingMethod: "NA",
    taxCalculationMethod: "NA",
    taxesOnFeesEnabled: false,
    taxesOnInterestEnabled: false,
    taxesOnPenaltyEnabled: false,
    taxSourceKey: "NA",
    internalControls: [],
    productFeeForms: [],
    repaymentAllocationOrderModel: [],
    repaymentAllocationOrderEnum: [
      {
        id: 0,
        name: "Fee",
        description: "Fee",
      },
      {
        id: 1,
        name: "Penalty",
        description: "Penalty",
      },
      {
        id: 2,
        name: "Interest",
        description: "Interest",
      },
      {
        id: 3,
        name: "Principal",
        description: "Principal",
      },
    ],
    adminArrearsResponseModel: {
      defaultTolerancePercentageOfOutstandingPrincipal: null,
      defaultTolerancePeriod: null,
      maxTolerancePercentageOfOutstandingPrincipal: null,
      maxTolerancePeriod: null,
      minTolerancePercentageOfOutstandingPrincipal: null,
      minTolerancePeriod: null,
      monthlyToleranceDsay: 1,
    },
    maxTolerancePercentageOfOutsatndingPrincipal: null,
    adminProductSecuritySetting: {
      funderInterestCommissionAllocationType: 0,
      isCollateralEnabled: false,
      isGuarantorsEnabled: false,
      isInvestorFundsEnabled: false,
      lockFundsAtApproval: false,
      requiredGuaranties: '',
      requiredInvestorFunds: 0,
    },
    adminInterestProductSettings: {
      interestProductSettingsKey: "NA",
      allowNegativeInterestRate: 0,
      defaultInterestRate: null,
      interestRateCeilingValue: 0,
      interestRateFloorValue: 0,
      maxInterestRate: null,
      minInterestRate: null,
    },
    adminPrincipalPaymentproductSetting: {
      principalPaymentProductSettingsKey: "NA",
      defaultAmount: 0,
      defaultPercentage: 0,
      maxAmount: 0,
      maxPercentage: 0,
      minAmount: 0,
      minPercentage: 0,
    },
    adminProductRedrawSetting: {
      productRedrawSettingsKey: "NA",
      allowRedraw: false,
    },
    adminIndexRateSource: {
      rateSourceName: "NA",
      rateSourceNotes: "NA",
      rateSourceType: "NA",
    },
    allowAAutoDisbursement: false,
    allowUnderwriterOveride: false,
    requireOfferAcceptance: false,
    loanPenaltyGracePeriodError: false,
    defaultPenaltyRateError: false,
    minPenaltyRateError: false,
    maxPenaltyRateError: false,
    repaymentPeriodUnitError: false,
    productFeePayments: [
      { id: 0, name: "Flat" },
      { id: 1, name: "% of Disbursement Amount" },
      { id: 2, name: "% of Repayment Principal Amount" },
      { id: 3, name: "Flat/Num of Installments" },
      { id: 4, name: "% of Disbursement Amount/Num of Installments" },
    ],
    paymentHoliday: {
      AllowPaymentHoliday: false,
      IsPercentageType: false,
      IsCustomType: false,
      IsFlatType: false,
      FeeAmount: 0,
      MaximumPaymentNumber: 0,
      FeePercentage: 0
    },
  };
};
export const LoanAccountDetails = function () {
  return {
    enums: product_enums,
    isPendingState: false,
    isLoading: false,
    editScheduleModal: false,
    openAddFeeModal: false,
    openRepaymentModal: false,
    customerLoanDeposits: [],
    openPayOffModal: false,
    openAppliedFee: false,
    openAddField: false,
    editPenaltyModal: false,
    lockAccountModal: false,
    unLockAccountModal: false,
    openCloseRefinance: false,
    openRescheduleModal: false,
    openWriteOff: false,
    openAttachmentModal: false,
    transactionPrevStatus: false,
    showEditTransactionModal: false,
    showViewTransactionModal: false,
    showSecurity: false,
    uiProps: {
      exportOptions: ["PDF", "Excel"],
      editField: false,
      loadingBranchList: false,
      loadingAccountTransactions: false,
      branchList: [],
      branchKey: null,
      accountSummary: null,
      accountTransactions: [],
      accountSchedules: null,
      repaymentSchedules: null,
      accountSchedule: null,
      accountActivities: null,
      accountAttachments: null,
      accountComments: null,
      accountSummaryTotal: 100,
      customFieldSets: [],
      accountCustomFieldSets: [],
      loadingAccountSummary: false,
      loadingAccountSchedules: false,
      loadingRepaymentSchedules: false,
      loadingAccountSchedule: false,
      loadingAccountActivities: false,
      loadingAccountAttachments: false,
      loadingAccountComments: false,
      loadingCustomFieldSets: false,
      loadingAccountCustomFieldSets: false,
      columns: [
        {
          title: "Account Name",
        },
        {
          title: "Type",
        },
        {
          title: "State",
        },
        {
          title: "Balance",
        },
      ],
      showEditComment: false,
      loadingAccountBalance: false,
      comment: null,
      accountBalance: null,
      customFieldSetKey: null,
      customFieldKey: null,
      customFieldValue: null,
      customFields: [],
      transaction: null,
    },
    showPreview: false,
    imagePreviewDataType: "",
    imagePreviewDataName: "",
    page: 0,
    size: 5,
    status: true,
    category: null,
    confirm_status: false,
    confirm_delete: false,
    loanProduct: null,
    loanProductKey: "",
    loanProductStatus: false,
    alert: {
      show: false,
      status: "",
      title: "Success",
      description: "Loan Product has been created",
    },
    process: false,
    showCreateModal: false,
    showEditModal: false,
    filter_by: "deactivated",
    filter_: "All Branches <ion-icon name='caret-down-outline'/>",
    export_: "Export <ion-icon name='caret-down-outline'/>",
    content_header: {
      title: "Account Summary",
      subTitle: "",
    },
    showPenaltyModal: false,
    loanAccount: {},
    selectedLoanProduct: {},
    openCommentModal: false,
  };
};
